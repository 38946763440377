import React from 'react'
import './career.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import logo from '../home/images/logo.png';
import { Helmet } from 'react-helmet-async'

function Career() {

    const jobs = [
        {
            title: "Content Writer",
            days: "2 days",
            link: "/"
        },
        {
            title: "Community Marketing Intern - Japan and South Korea",
            days: "13 days",
            link: "/"
        },
        {
            title: "Business Development Manager",
            days: "1 month",
            link: "/"
        },
        {
            title: "TechOps Automation (Intern)",
            days: "1 month",
            link: "/"
        },
        {
            title: "Senior Growth Hacking Manager (DeFi)",
            days: "2 months",
            link: "/"
        },
        {
            title: "Community Manager (Portuguese speaking)",
            days: "2 months",
            link: "/"
        },
        {
            title: "Community Manager (English and Spanish - speaking)",
            days: "2 months",
            link: "/"
        },
        {
            title: "Tech Writer (Intern)",
            days: "5 months",
            link: "/"
        },
        {
            title: "Senior Growth Hacking Manager",
            days: "5 months",
            link: "/"
        },
        {
            title: "Public Relations & Communications Manager",
            days: "6 months",
            link: "/"
        }
    ]
    return (
        <div>
            <Helmet>
                <title>Career Paths at Ultrapro Blockchain</title>
                <meta name='description' content='Exciting blockchain career opportunities await at Ultrapro Blockchain. Be part of the journey to transform digital finance and technology'></meta>
                <meta name='keywords' content=' Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer1 blockchain, EVM Compatible, PoA Consensus Algorithm, careers in blockchain technology,career in blockchain technology in india,Is blockchain in demand in India?,career opportunities in blockchain,blockchain career salary,blockchain career scope'></meta>
            </Helmet>
            <div className="career_section_1 ">
                <div className="container d-flex flex-column align-items-center justify-content-center">
                    <h1>ULTRAPRO BLOCKCHAIN<br /> ECOSYSTEM JOBS</h1>
                    <p>Bring blockchain to the people. Solana supports experiences
                        for power users, new consumers, and everyone in between.</p>
                    <button>Careers</button>
                </div>
            </div>

            <div className="career_section_2">
                <div className="container d-flex justify-content-between">
                    <label>Explore Companies</label>
                    <label>My job alerts</label>
                </div>
            </div>

            <div className="career_section_3">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 logo_container">
                            <img src={logo} alt='logo' />
                            <p>ULTRAPRO</p>
                            <Link to={'/'} className='text-decoration-none'>www.ultrapro.info</Link>

                            <div className="info_container mt-5">
                                <p>INDUSTRY</p>
                                <p>Blockchain and Crypto</p>
                                <p>SIZE</p>
                                <p>51-200 employees</p>
                                <p>STAGE</p>
                                <p>Other</p>
                                <p>FOUNDED IN</p>
                                <p className="mb-5">2024</p>
                                <Link to="#">Web3tools</Link>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="filter row">
                                <input className="filter_input col-md-4" type="text" placeholder="Search by title or keyword" style={{ fontFamily: "Arial, FontAwesome" }} />
                                <select className="filter_input col-md-3 my-3 my-md-0">
                                    <option>On site & Remote</option>
                                    <option>Work from Office</option>
                                    <option>Remote</option>
                                    <option>Hybrid</option>
                                </select>
                                <select className="filter_input col-md-4">
                                    <option>Location</option>
                                </select>
                                <div className='px-0 px-md-2'>
                                    <div className="mt-3 d-flex justify-content-between">
                                        <select className="filter_input col-4 col-lg-3">
                                            <option>Job function</option>
                                        </select>
                                        <button><FontAwesomeIcon icon={faBell} shake className='me-2' />Create job alert</button>
                                    </div>
                                </div>
                            </div>

                            <div className="job-list">
                                <label>Showing <span>{jobs.length}</span> jobs</label>
                                {jobs.map((item, index) => (
                                    <div className="job-content row align-items-center" key={index}>
                                        <div className="col-11 col-md-9 col-xl-10">
                                            <h6>{item.title}</h6>
                                            <p>{item.days}</p>
                                            <Link to="#">Web3tools</Link>
                                        </div>
                                        <div className="col-1 col-md-3 col-xl-2 read-more">
                                            <Link to={item.link}><span>Read more</span> <FontAwesomeIcon icon={faChevronRight} className='right-icon'/></Link>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Career