import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

function AboutUsSection2() {
  const ObjData = [
    {
      website: "NFT",
      Link: "https://upronft.com/",
    },
    {
      website: "Explorer",
      Link: "https://ultraproscan.io/",
    },
    {
      website: "Wallet",
      Link: "https://www.amazon.com/dp/B0CLKVQX1Q",
    },
    {
      website: "DeFi",
      Link: "https://eenaswap.finance/",
    },
    {
      website: "Blockchain",
      Link: "https://www.ultrapro.info/",
    },
    {
      website: "PRO20",
      Link: "https://www.amazon.com/dp/B0CLKVQX1Q",
    },
    {
      website: "Exchange",
      Link: "https://www.ultraproex.com/",
    },
    {
      website: "UPRO",
      Link: "https://coinmarketcap.com/currencies/ultrapro/",
    },
    {
      website: "Payments",
      Link: "https://upropayments.com/",
    },
    {
      website: "Expo",
      Link: "/expo",
    },
  ];

  const scrollRef = useRef(null); // To reference the scrollable container
  const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const [startX, setStartX] = useState(0); // Store the initial click X position
  const [scrollLeft, setScrollLeft] = useState(0); // Store initial scroll position

  const handleMouseDown = (e) => {
    const scrollElement = scrollRef.current;
    setIsDragging(true);
    setStartX(e.pageX - scrollElement.offsetLeft); // Get initial click position
    setScrollLeft(scrollElement.scrollLeft); // Get current scroll position
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return; // Do nothing if not dragging
    e.preventDefault();
    const scrollElement = scrollRef.current;
    const x = e.pageX - scrollElement.offsetLeft; // Current mouse position
    const walk = (x - startX) * 2; // The multiplier controls scroll speed
    scrollElement.scrollLeft = scrollLeft - walk; // Update scroll position
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false); // Stop dragging
  };

  return (
    <div
      className="about_us_section_2 scroll-container container"
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUpOrLeave}
      onMouseLeave={handleMouseUpOrLeave}
    >
      <ul className="d-flex text-white p-0 px-3 gap-4">
        {ObjData.map((item, index) => (
          <li key={index}>
            <Link to={item.Link} target="_blank">{item.website}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AboutUsSection2;
