import React from "react";

function Images({ link, styles, alt, height,onclick}) {
  return (
    <img
      className={styles}
      src={link}
      alt={alt}
      height={height}
      onClick={onclick}
    />
  );
}

export default Images;
