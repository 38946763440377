import React, { useState, useEffect, useContext, useMemo } from 'react'
import tile from '../images/tile.png';
import joystick from '../images/joystick.png'
import pie from '../images/pie.png';
import tile_light from '../images/tile_light.png';
import pie_light from '../images/pie_light.png';
import joystick_light from '../images/joystick_light.png';
// import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../Layout/ThemeContext';
import layer_1 from '../images/layer-1-tech.png';
import algorithm from '../images/cons-algo.png';
import robust from '../images/rob-sec.png';

function HomeSection5() {
    const [activetab, setActiveTab] = useState(0);
    const tabs = useMemo(()=> ['v-pills-layer1-tab', 'v-pills-security-tab', 'v-pills-algorithm-tab'],[]);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveTab((pv) => (pv + 1) % tabs.length);
        }, 3000)
        return () => clearInterval(interval);

    }, [tabs.length])

    useEffect(() => {
        document.getElementById(tabs[activetab]).click();
    }, [activetab, tabs]);

    return (
        <div className="section_5 d-none d-lg-block">
            <div className="container my-5">
                <div>
                    <h3 className="text-center">Major advantages of leveraging Ultrapro blockchain</h3>
                    <div className="align-items-start vertical_menu d-flex p-4 flex-column flex-lg-row ">
                        <div className="nav flex-column nav-pills me-3 col-md-3 position-relative justify-content-between" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className="w-100">
                                <button className="nav-link v-nav-link active d-flex align-items-center justify-content-start gap-2" id="v-pills-layer1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-layer1" type="button" role="tab" aria-controls="v-pills-layer1" aria-selected="true"><img loading="lazy" src={theme === 'dark' ? tile : tile_light} alt='tile' /> Layer 1 Technology</button>
                                <button className="nav-link v-nav-link d-flex align-items-center justify-content-start gap-2 my-2" id="v-pills-security-tab" data-bs-toggle="pill" data-bs-target="#v-pills-security" type="button" role="tab" aria-controls="v-pills-security" aria-selected="false"><img loading="lazy" src={theme === 'dark' ? joystick : joystick_light} alt='joystick' /> Robust Security</button>
                                <button className="nav-link v-nav-link d-flex align-items-center justify-content-start gap-2" id="v-pills-algorithm-tab" data-bs-toggle="pill" data-bs-target="#v-pills-algorithm" type="button" role="tab" aria-controls="v-pills-algorithm" aria-selected="false"><img loading="lazy" src={theme === 'dark' ? pie : pie_light} alt='pie' /> Consensus Algorithm(POA)</button>
                            </div>
                            {/* <p>Check out the <span className="text-orange">roadmap</span> to discover upcoming opportunities.</p> */}
                        </div>

                        <div className="tab-content col-md-8 v_menu_content" id="v-pills-tabContent">
                            <div className="tab-pane v-tab-pane fade show active" id="v-pills-layer1" role="tabpanel" aria-labelledby="v-pills-layer1-tab">
                                <div className="row">
                                    <div className="col-md-7 d-flex flex-column justify-content-between">
                                        <div>
                                            <h2>Layer 1 Technology</h2>
                                            <p>Ultrapro integrates Layer 1 technology, augmenting its functionalities and performance metrics. This fundamental layer serves as the cornerstone, assuring resilience, scalability, and operational efficiency, thereby establishing a robust infrastructure to accommodate a wide array of applications and use cases within the blockchain ecosystem.</p>
                                        </div>
                                        {/* <Link to="#">Know More</Link> */}
                                    </div>
                                    <div className="col-md-5">
                                        <img loading="lazy" src={layer_1} alt='layer-1-technology' className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane v-tab-pane fade" id="v-pills-security" role="tabpanel" aria-labelledby="v-pills-security-tab">
                                <div className="row">
                                    <div className="col-md-7 d-flex flex-column justify-content-between">
                                        <div>
                                            <h2>Robust Security</h2>
                                            <p>With its advanced architecture and sophisticated consensus algorithm, UltraPro sets the gold standard in security within the blockchain industry. It employs rigorous encryption protocols and decentralized validation mechanisms to ensure the utmost protection of digital assets and transactions against potential threats. UltraPro's commitment to security extends across its entire ecosystem.</p>
                                        </div>
                                        {/* <Link to="#">Know More</Link> */}
                                    </div>
                                    <div className="col-md-5">
                                        <img loading="lazy" src={robust} alt='robust-security' className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane v-tab-pane fade" id="v-pills-algorithm" role="tabpanel" aria-labelledby="v-pills-algorithm-tab">
                                <div className="row">
                                    <div className="col-md-7 d-flex flex-column justify-content-between">
                                        <div>
                                            <h2>Consensus Algorithm(POA)</h2>
                                            <p>Utilizing Proof of Authority (PoA) as its consensus algorithm, Ultrapro blockchain guarantees a secure and efficient network operation. Through a designated cohort of trusted validators, PoA optimizes transaction processing speeds and scalability, surpassing conventional algorithms such as Proof of Work (PoW) or Proof of Stake (PoS).</p>
                                        </div>
                                        {/* <Link to="#">Know More</Link> */}
                                    </div>
                                    <div className="col-md-5">
                                        <img loading="lazy" src={algorithm} alt='consensus-algorithm' className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeSection5