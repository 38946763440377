import React, { useContext, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts'; // Make sure you have this installed
import { ThemeContext } from '../../../Layout/ThemeContext';

const ApexChart = ({currentPrice}) => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const generateDates = () => {
      const last15Dates = [];
      const currentDate = new Date();
      for (let i = 14; i >= 0; i--) {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);
        // last15Dates.push(date.toISOString().split('T')[0]);//yyyy-mm-dd
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        last15Dates.push(`${day}/${month}`);
      }
      return last15Dates
    }
    setDates(generateDates())
  }, [])



  const { theme } = useContext(ThemeContext);
  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: "UPRO",
        data: [0.0129, 0.0132, 0.0133, 0.0135, 0.0141, 0.0144, 0.0146, 0.0150, 0.0151, 0.0160, 0.0161, 0.0162, 0.0163, 0.0164, currentPrice]
      }
    ],
    
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      markers: {
        size: 3,
      },
      tooltip:{
        enabled: false,
      },
      colors: ['#FF8300'],
      dataLabels: {
        enabled: false,
        background: {
          enabled: true,
          foreColor: '#000',
          borderRadius: 6,
          borderWidth: 0,
          borderColor: '#FFF',
          padding: 4,
        }
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: dates,
        labels: {
          style: {
            colors: '#000'  // Default color 
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#000'  // Default color
          }
        }
      }
    }
  });


  useEffect(() => {
    setChartOptions((prevState) => ({
      ...prevState,
      series: [
        {
          name: "UPRO",
          data: [0.0129, 0.0132, 0.0133, 0.0135, 0.0141, 0.0144, 0.0146, 0.0150, 0.0151, 0.0160, 0.0161, 0.0162, 0.0163, 0.0164, currentPrice]
        }
      ],
      options: {
        ...prevState.options,
        markers: {
          colors: theme === 'dark' ? '#fff' : '#000',
          strokeWidth: 0
        },
        grid: {
          borderColor: theme === 'dark' ? '#D6D6D6' : '#787878',
        },
        xaxis: {
          ...prevState.options.xaxis,
          categories: dates,

          labels: {
            style: {
              colors: theme === 'dark' ? '#fff' : '#000'
            }
          }
        },
        yaxis: {
          ...prevState.options.yaxis,
          labels: {
            style: {
              colors: theme === 'dark' ? '#fff' : '#000'
            }
          }
        }
      }
    }));
  }, [theme, dates, currentPrice]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="line"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
