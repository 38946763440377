import React from 'react'
import './style.css'
import AboutUsSection1 from './components/aboutUsSection1';
import AboutUsSection2 from './components/aboutUsSection2';
import AboutUsSection3 from './components/aboutUsSection3';
import AboutUsSection4 from './components/aboutUsSection4';
import AboutUsSection5 from './components/aboutUsSection5';
import AboutUsSection6 from './components/aboutUsSection6';
import { Helmet } from 'react-helmet-async';

function Aboutus() {

    return (
        <div>
            <Helmet>
                <title>Ultrapro Blockchain About us</title>
                <meta name='description' content='Join UltraPro Blockchain to decentralize the internet with UPRO coin, Layer One tech, DeFi solutions, and an NFT platform. Innovate with us!'></meta>
                <meta name='keywords' content='Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer1 blockchain, EVM Compatible, PoA Consensus Algorithm, Ultrapro Wallet'></meta>
            </Helmet>
            <AboutUsSection1 />
            <AboutUsSection2 />
            <AboutUsSection3 />
            <AboutUsSection4 />
            <AboutUsSection5 />
            <AboutUsSection6 />
        </div>
    )
}

export default Aboutus