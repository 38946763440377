import React from 'react'
import blog1_img from '../images/blog_1.png';
import blog2_img from '../images/blog_2.png';
import blog3_img from '../images/blog_3.png';
import blog4_img from '../images/blog_4.png';
import blog5_img from '../images/blog_5.png';
import blog6_img from '../images/blog_6.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function PressRelease() {
    const pr_blog = [
        {
            image: blog1_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog2_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog3_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog4_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog5_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog6_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog1_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog2_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        },
        {
            image: blog3_img,
            heading: "MTD is Live on Ultrapro's USDT Market",
            date: "Jun 3, 2024",
        }
    ]
  return (
    <div>
    <h2>INDIA’S MOST TRUSTED BITCOIN & CRYPTO BLOG</h2>
    <div className="blog-list row">
        {pr_blog.map((item, index) => (
            <div key={index} className="blog-card col-md-4 col-12 my-4">
                <Link to="#">
                    <img src={item.image} alt='blog-image' />
                    <div className="blog-content">
                        <h4>{item.heading}</h4>
                        <div className="d-flex">
                            <div className="col-12 text-white d-flex align-items-center justify-content-center mb-4">
                                <p>{item.date}</p>
                                <FontAwesomeIcon icon={faArrowRight} className='ms-3 right-arrow'/>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        ))}

        <nav aria-label="Page navigation example">
            <ul className="pagination d-flex justify-content-center gap-3 align-items-center">
                <li className="page-item">
                    <Link className="page-link" to="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </Link>
                </li>
                <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                <li className="page-item">
                    <Link className="page-link" to="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </Link>
                </li>
            </ul>
        </nav>
    </div>
</div>
  )
}

export default PressRelease