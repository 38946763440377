import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import placeholder_1 from '../images/placeholder_1.png';
import { Link } from 'react-router-dom';
import Aos from 'aos';


function HomeSection1() {

    const [countryCount, setCountryCount] = useState(0);
    const [transCount, setTransCount] = useState(0);
    const [tradeCount, setTradeCount] = useState(0);



    useEffect(()=>{
        const interval = setInterval(()=>{
            setCountryCount((pv)=>{
                if(pv < 35){
                    return pv + 1
                }else{
                    clearInterval(interval);
                    return pv
                }
            })
        },150)
        const transInterval = setInterval(()=>{
            setTransCount((pv)=>{
                if(pv < 20){
                    return pv + 1
                }else{
                    clearInterval(transInterval);
                    return pv
                }
            })
        },150)

        const tradeInterval = setInterval(()=>{
            setTradeCount((pv)=>{
                if(pv < 10){
                    return pv + 1
                }else{
                    clearInterval(tradeInterval);
                    return pv
                }
            })
        },150)

        

        return ()=> {
            clearInterval(interval)
            clearInterval(transInterval);
            clearInterval(tradeInterval);
        }
    },[])

    const card = [
        {
            count: `${countryCount}+`,
            description: "Countries"
        },
        {
            count: `${transCount}K+`,
            description: "Transactions"
        },
        {
            count: `${tradeCount}L+`,
            description: "Trades Per Day"
        }
    ]

    useEffect(()=>{
        Aos.init()
    },[])
    return (
        <>
            <div className="section_1 " >
                <div className="container">
                    <div className="row align-items-center" data-aos="fade-left">
                        <div className="col-md-7">
                            <h1><span>Ultrapro</span> Blockchain <br />Powering the Next Digital Era</h1>
                            {/* <p>Enter a new era of financial possibilities with Ultrapro Blockchain - your portal to a decentralized world of limitless innovation.</p> */}
                            <div className="features">
                                <label className="d-flex gap-2 align-items-center"><FontAwesomeIcon icon={faCheck} />Layer 1 Blockchain</label>
                                <label className="d-flex gap-2 align-items-center"><FontAwesomeIcon icon={faCheck} />Advanced Consensus Algorithm</label>
                                <label className="d-flex gap-2 align-items-center"><FontAwesomeIcon icon={faCheck} />Unmatched Network Scalability</label>
                            </div>
                            <div className="explore-action">
                                <Link className="btn learn_more" to="https://ultraproscan.io/" target='_blank'>UPRO Explore</Link>
                            </div>
                            <div className="d-flex align-items-center count-container mb-4 flex-wrap justify-content-center justify-content-md-start">
                                {card.map((item, index) => (
                                    <div key={index} className="cards">
                                        <p>{item.count}</p>
                                        <p>{item.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-5 placeholder_1_container" >
                            <img src={placeholder_1} alt='placeholder' className="img-fluid placeholder_1" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default HomeSection1