import React from 'react'
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function AboutUsSection6() {
  return (
    <div className="about_us_section_6">
                <div className="container">
                    <h2>CORE VALUES</h2>
                    <div className="row justify-content-center">
                        <div className="col-sm col-lg-3 col-12  features_card">
                            <h2 className="my-5">Integrity</h2>
                            <p className="px-3">We prioritize ethical practices and transparency in all our operations</p>
                            {/* <Link to="#" className="my-4">Get Started <FontAwesomeIcon icon={faArrowRight} className='ms-1' /></Link> */}
                        </div>
                        <div className="col-sm col-lg-3 col-12  features_card">
                            <h2 className="my-5">Innovation</h2>
                            <p className="px-3">We strive to push the boundaries of technology and create cutting-edge solutions</p>
                            {/* <Link to="#" className="my-4">Get Started <FontAwesomeIcon icon={faArrowRight} className='ms-1' /></Link> */}
                        </div>
                        <div className="col-sm col-lg-3 col-12  features_card">
                            <h2 className="my-5">Community</h2>
                            <p className="px-3">We believe in building strong relationships with our users and stakeholders</p>
                            {/* <Link to="#" className="my-4">Get Started <FontAwesomeIcon icon={faArrowRight} className='ms-1' /></Link> */}
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default AboutUsSection6