import React from "react";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faLinkedin,
  faTwitter,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Sociel() {
  const social_media = [
    {
      logo: faInstagram,
      name: "Instagram",
      link: "https://www.instagram.com/ultraproupdates/",
    },
    {
      logo: faFacebook,
      name: "Facebook",
      link: "https://www.facebook.com/ultraproupdates/",
    },
    {
      logo: faYoutube,
      name: "YouTube",
      link: "https://www.youtube.com/@ultraproupdates",
    },
    {
      logo: faLinkedin,
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/ultrapro-blockchain/",
    },
    {
      logo: faTwitter,
      name: "Twitter",
      link: "https://twitter.com/Ultraproupdates",
    },
    {
      logo: faTelegram,
      name: "Telegram",
      link: "https://t.me/ultrapro_announcement",
    },
  ];
  return (
    <div className="section_11 text-center">
      <div className="container">
        <h3>
          Grow with <span>Ultrapro’s</span> Global Network
        </h3>
        <p>
          Connect with blockchain enthusiasts and innovators worldwide in the
          Ultrapro Community. Gain insights, share ideas, and be part of a
          network driving the future of digital finance.
        </p>
        <div className="row align-items-center text-center justify-content-center my-5">
          {social_media.map((item, index) => (
            <Link
              key={index}
              className={`col-6 col-lg-2 col-md-4 community text-decoration-none my-3 my-lg-0`}
              to={item.link}
              target="_blank"
            >
              <div className="social-card d-flex flex-column gap-2 align-items-center">
                <FontAwesomeIcon icon={item.logo} className="som-icon" />
                <label>{item.name}</label>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>                   
  );
}

export default Sociel;
