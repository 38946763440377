import React, { useState } from 'react'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postRequest from '../../../Layout/apifetch';
import { config } from '../../../config';

function ContactForm() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [disable, setdisable] = useState(false);

    const onSubmit = async (data) => {
        setdisable(true);
        const { name, email, whatsappNum, message } = data;
        const subject = `New Request from ${data.name}`
        const osType = 0;
        const payload = { name, email, subject, osType, message };
        const url = config.contact_url;
        const headers = {};

        try {
            const result = await postRequest(url, payload, headers);
            if (result) {
                toast.success('Email Sent Successfully', { theme: 'colored' })
                reset();
                setdisable(false);
            }
        }
        catch(error){
            console.error(error)
        }
    };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <h5>Contact</h5>
                <Form.Group controlId="formBasicName" className='formGroup'>
                    <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        {...register('name', { required: 'Name is required' })}
                    />
                    {errors.name && <span style={{ color: 'red' }}>{errors.name.message}</span>}
                </Form.Group>

                <Form.Group controlId="formBasicEmail" className='formGroup'>
                    <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: 'Invalid email address'
                            }
                        })}
                    />
                    {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
                </Form.Group>

                <Form.Group controlId="formBasicwhatsappNum" className='formGroup'>
                    <Form.Control
                        type="text"
                        placeholder="Whatsapp Number"
                        {...register('whatsappNum', {
                            required: 'Whatsapp Number is required',
                            pattern: {
                                value: /^[0-9]+$/,
                                message: 'Whatsapp Number must be a valid numeric value'
                            },
                            minLength: {
                                value: 10,
                                message: 'Whatsapp Number must be at least 10 digits'
                            },
                            maxLength: {
                                value: 10,
                                message: 'Whatsapp Number must be 10 digits'
                            }
                        })}
                    />
                    {errors.whatsappNum && <span style={{ color: 'red' }}>{errors.whatsappNum.message}</span>}
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1" className='formGroup'>
                    <Form.Control
                        as="textarea"
                        style={{ height: "140px" }}
                        placeholder="Enter message"
                        rows={3}
                        {...register('message', { required: 'Message is required' })}
                    />
                    {errors.message && <span style={{ color: 'red' }}>{errors.message.message}</span>}
                </Form.Group>
                <Button
                    disabled={disable}
                    style={{ cursor: disable ? 'not-allowed' : 'pointer' }}
                    variant="primary"
                    className="mt-3 mt-md-0 w-100 text_whites btn btn-org"
                    type="submit"
                >
                    {disable ? "Sending..." : "Submit"}
                </Button>
            </Form>

            <ToastContainer />
        </>

    )
}

export default ContactForm