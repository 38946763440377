import "./App.css";
import Home from "./pages/home/home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./pages/nav/nav";
import Footer from "./pages/footer/footer";
import Aboutus from "./pages/aboutUs/about_us";
import Blog from "./pages/blog/blog";
import Career from "./pages/careers/career";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import TermsUSe from "./pages/termsUse/termsUse";
import Expo from "./pages/expo/expo";
import Enhanced from "./pages/enhanceds/enhanced";
import Contact from "./pages/contacts/contact";
import ScrollToTop from "./Layout/scrollToTop";
import PageNotFound from "./Layout/pageNotFound";
import ThemeProvider from "./Layout/ThemeContext";
import OurTeam from "./pages/ourTeam/ourTeam";
import { HelmetProvider } from "react-helmet-async";


function App() {
  return (
    <HelmetProvider>
      <Router>
        <ThemeProvider>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<Aboutus />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/career" element={<Career />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsUse" element={<TermsUSe />} />
            <Route path="/expo" element={<Expo />} />
            <Route path="/Enhanced" element={<Enhanced />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/ourTeam" element={<OurTeam />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </ThemeProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
