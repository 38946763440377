import React, { useContext, useEffect, useRef, useState } from 'react'
import './policy.css'
import { Link } from 'react-router-dom'
import logo from '../home/images/logo.png';
import { Helmet } from 'react-helmet-async';
import { ThemeContext } from '../../Layout/ThemeContext';

function PrivacyPolicy() {
    const ques = ["1. Who We Are and Our Approach",
        "2. Personal Information We Collect",
        "3. Layer 1 Blockchain Technology: Speed, Security, and Scalability",
        "4. Ultrapro Wallet",
        "5. Consensus Algorithm",
        "6. Token Economy: A Thriving Ecosystem",
        "7. DeFi and Staking",
        "8. Why We Collect Personal Information",
        "9. Cookies and Tracking Technologies",
        "10. Sharing of Personal Information",
        "11. International Data Transfers",
        "12. Security of Your Information",
        "13. Your Rights and Choices",
        "14. Educational Resources",
        "15. Transparency and Governance",
        "16. Data Retention",
        "17. Updates to this Privacy Policy"
    ]
    const ansRef = useRef([]);
    const [currentIndex, setCurrentIndex] = useState(null)

    const handleScroll = (index) => {
        setCurrentIndex(index);
        if (ansRef.current[index]) {
            const offset = 80; //navbar height
            const elementPosition = ansRef.current[index].getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({ top: offsetPosition });
        }
    }
    useEffect(() => {
        const currentRefs = ansRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = ansRef.current.indexOf(entry.target);
                        setCurrentIndex(index);
                    }
                });
            },
            {
                root: null, // relative to the viewport
                rootMargin: '0px',
                threshold: 1, // trigger when 100% of the section is visible
            }
        );

        currentRefs.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        // Cleanup the observer when component unmounts
        return () => {
            currentRefs.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [])

    const { theme } = useContext(ThemeContext);
    return (
        <div>
            <Helmet>
                <title>Ultrapro Blockchain Privacy Policy</title>
                <meta name='description' content='Ultrapro Blockchain prioritizing transparency and security. See how your data is collected, used, and protected, ensuring compliance with data protection laws'></meta>
                <meta name='keywords' content='Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer1 blockchain, EVM Compatible, PoA Consensus Algorithm, Ultrapro Wallet'></meta>
            </Helmet>
            <div className="policy_section_1 ">
                <div className="container d-flex flex-column align-items-center justify-content-center">
                    <p>Last Updated : October 2024</p>
                    <h1>ULTRAPRO BLOCKCHAIN<br /> PRIVACY POLICY</h1>
                    <button>Download</button>
                </div>

            </div>

            <div className="policy_section_2">
                <div className="container">
                    <label>Privacy Policy</label>
                </div>
            </div>

            <div className="policy_section_3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 logo_container">
                            <img src={logo} alt='logo' />
                            <p>ULTRAPRO</p>
                            <Link to={'/'} className='text-decoration-none'>www.ultrapro.info</Link>
                            <div id="list-policy" className="list-group mt-5">
                                {ques.map((ques, index) => (
                                    <button key={index} className={`list-group-item list-group-item-action ${currentIndex === index ? theme === 'dark' ? 'fw-bold text-white' : 'fw-bold text-black' : ''}`} onClick={() => handleScroll(index)}>{ques}</button>
                                ))}

                            </div>

                        </div>
                        <div className="col-md-8 policy_content">
                            <p>Welcome to Ultrapro Blockchain! Your privacy and data security are top priorities for us. This Privacy Policy explains how Ultrapro Blockchain Tech Pvt Ltd. and its affiliates (“Ultrapro Blockchain”) collect, use, share, and protect your personal information across our ecosystem. This ecosystem includes Ultrapro Blockchain websites, mobile applications, clients, and connected applications (collectively, the “Websites”). By interacting with our Websites, you consent to the practices outlined in this Privacy Policy</p>
                            <div className="list_item" >
                                <>
                                    <h4 ref={(el) => (ansRef.current[0] = el)} id="list-item-1">1. Who We Are and Our Approach</h4>
                                    <p>Ultrapro Blockchain is a pioneering platform in the blockchain space, bringing together a community of users and developers (the “Community”) to collectively advance decentralized technology. Our Websites act as an interface, facilitating collaboration and innovation on public, decentralized, and permissionless blockchains, where the Community itself drives collective decisions. Ultrapro Blockchain does not control or direct the activities or data usage on these public blockchains.</p>
                                    <p>Our services (the “Services”) are available through our network of subsidiaries, each of which may operate as an independent Data Controller for personal information collected within its jurisdiction.</p>

                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[1] = el)} id="list-item-2">2. Personal Information We Collect</h4>
                                    <p>To personalize and enhance your experience with Ultrapro Blockchain, we collect certain personal information:</p>
                                    <p><strong>Information You Provide to Us</strong></p>
                                    <p>We collect personal information that you voluntarily share with us, such as:</p>
                                    <ul>
                                        <li><strong>Contact Information:</strong> Email address, name, and country of residence.</li>
                                        <li><strong>Social Media Handles:</strong> LinkedIn, Twitter, or other platforms (Ultrapro Blockchain does not control these platforms; refer to their privacy policies).</li>
                                        <li><strong>Wallet Address:</strong> Required for accessing certain blockchain services across our ecosystem.</li>
                                        <li><strong>Account Information:</strong> For forums and community interactions, you may need to provide a username, email address, and password.</li>
                                    </ul>
                                    <p><strong>Automatically Collected Information</strong></p>
                                    <p>When you interact with our Websites, we automatically gather certain data for functionality and analytics, including:</p>
                                    <ul>
                                        <li><strong>Device Information:</strong> IP address, device location, browser type, and device settings.</li>
                                        <li><strong>Service Usage Metrics:</strong> Including service features you interact with, preferences, and any technical errors.</li>
                                    </ul>
                                    <p><strong>Event Registration Data</strong></p>
                                    <p>For events such as Hackathons, we may collect:</p>
                                    <ul>
                                        <li>Country of residence, university affiliation, or employer details</li>
                                        <li>Project information (e.g., pitch decks or whitepapers)</li>
                                        <li>Personal preferences for event participation</li>
                                    </ul>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[2] = el)} id="list-item-3">3. Layer 1 Blockchain Technology: Speed, Security, and Scalability</h4>
                                    <p>Built on robust Layer 1 technology, Ultrapro Blockchain delivers unparalleled transaction speed, advanced security, and scalability. This foundation is designed to handle high transaction volumes without compromising network security, making it ideal for large-scale applications and real-world adoption.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[3] = el)} id="list-item-4">4. Ultrapro Wallet</h4>
                                    <p>The Ultrapro Wallet is a user-friendly and secure digital wallet, providing seamless access to all assets within the Ultrapro ecosystem. Designed for high-grade security, it ensures that users have complete control over their funds and private keys, making it easy to store, send, and receive assets.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[4] = el)} id="list-item-5">5. Consensus Algorithm</h4>
                                    <p>Ultrapro Blockchain’s consensus algorithm is built for efficiency and security, supporting faster and more reliable transactions. This algorithm allows the network to reach consensus without compromising the decentralized nature of the platform, ensuring that all participants can trust the integrity of the system.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[5] = el)} id="list-item-6">6. Token Economy: A Thriving Ecosystem</h4>
                                    <p>The UPRO token is the backbone of the Ultrapro ecosystem, fueling transactions, incentivizing participants, and empowering governance. This token economy is crafted to promote active participation and fair value distribution among users, developers, and stakeholders.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[6] = el)} id="list-item-7">7. DeFi and Staking</h4>
                                    <p>Ultrapro Blockchain provides a decentralized finance (DeFi) platform with various staking opportunities, allowing users to earn passive income on their holdings. This feature opens doors to financial inclusion, enabling anyone to generate returns on their digital assets through secure and transparent mechanisms.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[7] = el)} id="list-item-8">8. Why We Collect Personal Information</h4>
                                    <p>We use your information to ensure smooth, secure, and tailored experiences on Ultrapro Blockchain, including:</p>
                                    <ul>
                                        <li><strong>Service Delivery:</strong> To enable functionality, monitor performance, and troubleshoot issues.</li>
                                        <li><strong>Personalization:</strong> To customize your experience and recommend relevant features.</li>
                                        <li><strong>Legal Compliance:</strong> To adhere to laws and regulations.</li>
                                        <li><strong>Security and Fraud Prevention:</strong> To safeguard our ecosystem from misuse and protect user security.</li>
                                        <li><strong>Event Participation:</strong> To facilitate your involvement in events and deliver an optimal experience.</li>
                                        <li><strong>Consent-Based Processing:</strong> For specific purposes, we may request your consent, which you can withdraw at any time.</li>
                                    </ul>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[8] = el)} id="list-item-9">9. Cookies and Tracking Technologies</h4>
                                    <p>We use cookies to enhance your experience by recognizing you, personalizing your settings, and securing our Websites. You can adjust your browser settings to manage cookie preferences or receive alerts about new cookies.</p>
                                </>


                                <>
                                    <h4 ref={(el) => (ansRef.current[9] = el)} id="list-item-10">10. Sharing of Personal Information</h4>
                                    <p>We respect your privacy and do not sell your personal information. We only share your data in limited cases:</p>
                                    <ul>
                                        <li><strong>Service Providers:</strong> For tasks like payment processing and marketing support, we engage trusted third-party providers who adhere to this Privacy Policy.</li>
                                        <li><strong>Business Transactions:</strong> If Ultrapro Blockchain undergoes a merger or acquisition, personal data may be transferred as an asset, subject to this Privacy Policy.</li>
                                        <li><strong>Legal Obligations and Protection:</strong> To comply with laws or protect our rights, we may disclose necessary information to authorities.</li>
                                    </ul>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[10] = el)} id="list-item-11">11. International Data Transfers</h4>
                                    <p>To provide our services, your data may be transferred outside the European Economic Area (EEA) to Ultrapro Blockchain entities and service providers that follow strict safeguards in line with the GDPR.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[11] = el)} id="list-item-12">12. Security of Your Information</h4>
                                    <p>We use encryption and follow rigorous security standards to protect your data. These measures are in place to prevent unauthorized access and ensure your information remains safe with us.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[12] = el)} id="list-item-13">13. Your Rights and Choices</h4>
                                    <p>Depending on your location, you may have specific rights regarding your personal information, such as:</p>
                                    <ul>
                                        <li><strong>Access:</strong> You can request a copy of your data.</li>
                                        <li><strong>Correction:</strong> We allow you to update inaccurate information.</li>
                                        <li><strong>Deletion:</strong> In certain cases, you may request the removal of your data.</li>
                                        <li><strong>Objection and Restriction:</strong> You can object to or restrict data processing under specific circumstances.</li>
                                        <li><strong>Portability:</strong> Where applicable, receive your data in a machine-readable format.</li>
                                        <li><strong>Post-Mortem Requests:</strong> Define how your data is managed after your death.</li>
                                        <li><strong>Withdrawal of Consent:</strong> For activities where consent is required, you may withdraw it at any time.</li>
                                    </ul>
                                    <p>To exercise these rights, contact us at  <Link className='mail' to="mailto: privacy@ultrapro.info">privacy@ultrapro.info</Link></p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[13] = el)} id="list-item-14">14. Educational Resources:</h4>
                                    <p>Ultrapro Blockchain is committed to educating its users, offering comprehensive resources on blockchain technology and decentralized finance. Through Probinar, our online education platform, users can enhance their knowledge, preparing them to thrive in the rapidly evolving blockchain space.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[14] = el)} id="list-item-15">15. Transparency and Governance</h4>
                                    <p>Ultrapro Blockchain emphasizes transparency and decentralization in governance. Community members have the power to propose, discuss, and vote on changes to the platform, ensuring that the system evolves to meet the needs of its users without centralized interference.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[15] = el)} id="list-item-16">16. Data Retention</h4>
                                    <p>We retain personal information as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, or meet contractual commitments.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[16] = el)} id="list-item-17">17. Updates to this Privacy Policy</h4>
                                    <p>Our Privacy Policy may be updated to reflect new practices or changes in law. We will notify you of significant updates through email or on our Websites. For the latest version, we encourage you to review this Privacy Policy periodically.</p>
                                </>

                                <>
                                    <p>Ultrapro Blockchain is committed to transparency and privacy. For any questions, reach out to us at <Link className='mail' to="mailto: privacy@ultrapro.info">privacy@ultrapro.info</Link></p>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy