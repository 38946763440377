import React, { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import event_1 from '../images/event_1.png';
import event_2 from '../images/event_2.png';
import event_3 from '../images/event_3.png';
import event_4 from '../images/event_4.png';

function ImageSlider() {
    const swiperRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);


    const handleNextSlide = () => {
        const sliderLength = swiperRef.current.slides.length;
        const currentSlide = swiperRef.current.activeIndex;
        if (swiperRef.current) {
            if (currentSlide === sliderLength - 1) {
                swiperRef.current.slideTo(0);
            } else {
                swiperRef.current.slideNext();
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <Swiper
                effect={'coverflow'}
                grabCursor={isMobile}
                centeredSlides={true}
                slidesPerView={1}
                pagination={{ clickable: true }}
                modules={[/*EffectCoverflow ,*/ Pagination]}
                className="mySwiper"
                allowTouchMove={isMobile}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
            >
                <SwiperSlide>
                    <div className="row align-items-center p-4 position-relative">
                        <div className="col-md-4 col-12">
                            <img loading='lazy' src={event_1} alt='event-1' className="img-fluid" />
                        </div>
                        <div className="col-md-6 col-12 social_media">
                            <p>UPRO Coin</p>
                            <p>Coin Launch      World Wide</p>
                            <p>UPRO has officially launched in October 2024, marking an exciting milestone in its journey. With remarkable growth and increasing adoption, we’re proud to witness its soaring success.</p>
                        </div>
                        <div className='col-md-2 next-event'>
                            <img loading='lazy' src={event_2} alt='event-2' className="img-fluid" onClick={handleNextSlide} />
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row align-items-center p-4 position-relative">
                        <div className="col-md-4 col-12">
                            <img loading='lazy' src={event_2} alt='event-1' className="img-fluid" />
                        </div>
                        <div className="col-md-6 col-12 social_media">
                            <p>Ultrapro Exchange</p>
                            <p>Launch Event      India</p>
                            <p>Join us for an unforgettable event as we unveil the future of cryptocurrency trading – the grand launch of Ultrapro Exchange! Our team has worked tirelessly to create an intuitive, secure, and innovative platform that will revolutionize the way you trade and experience digital assets.</p>
                        </div>
                        <div className='col-md-2 next-event'>
                            <img loading='lazy' src={event_3} alt='event-3' className="img-fluid" onClick={handleNextSlide} />
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row align-items-center p-4 position-relative">
                        <div className="col-md-4 col-12">
                            <img loading='lazy' src={event_3} alt='event-3' className="img-fluid" />
                        </div>
                        <div className="col-md-6 col-12 social_media">
                            <p>Celebrating Sustainability at TapyMax</p>
                            <p>Launch Event      India</p>
                            <p>At TapyMax, we celebrated our sales executives' success in promoting Green Energy Bikes and launched the Green Energy Federation. This initiative marks a significant step toward a sustainable, eco-friendly future.</p>
                        </div>
                        <div className='col-md-2 next-event'>
                            <img loading='lazy' src={event_4} alt='event-4' className="img-fluid" onClick={handleNextSlide} />
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row align-items-center p-4 position-relative">
                        <div className="col-md-4 col-12">
                            <img loading='lazy' src={event_4} alt='event-4' className="img-fluid" />
                        </div>
                        <div className="col-md-6 col-12 social_media">
                            <p>Probinar</p>
                            <p>Launch Event      India</p>
                            <p>Probinar offers recorded blockchain courses tailored for all skill levels—from beginners to advanced professionals. With expert guidance and real-world applications, our programs equip students to thrive in the blockchain industry.</p>
                        </div>
                        <div className='col-md-2 next-event'>
                            <img loading='lazy' src={event_1} alt='event-1' className="img-fluid" onClick={handleNextSlide} />
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    )
}

export default ImageSlider;
