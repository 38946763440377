import React from 'react'
import popup from '../images/popup.png';
import { Modal } from 'react-bootstrap';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ModalPopup({ setShowModal, showModal }) {
    const handleClose = () => setShowModal(false);
    return (

        <Modal
            show={showModal}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body className='p-0 position-relative' >
                <img src={popup} alt='popup' className='img-fluid' />
                <FontAwesomeIcon icon={faClose} className='modal-close-btn' onClick={handleClose}/>
            </Modal.Body>

        </Modal>

    )
}

export default ModalPopup