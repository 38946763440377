import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import Layouts from "../../../Layout/Childrens";

const AnimatedProgressBars = () => {
  const [progress, setProgress] = useState([0, 0, 0]);
  const targetValues = [20, 80, 100];

  useEffect(() => {
    const intervals = targetValues.map((finalValue, index) => {
      return setInterval(() => {
        setProgress((prev) => {
          const newProgress = [...prev];
          if (newProgress[index] < finalValue) {
            newProgress[index] = Math.min(newProgress[index] + 10, finalValue);
          }
          return newProgress;
        });
      }, 1000 + index * 1000);
    });

    return () => intervals.forEach(clearInterval);
  }, []);

  return (
    <div style={{ margin: "20px auto" }}>
      {targetValues.map((value, index) => (
        <Layouts key={index} styles="prograss_1 text-whites">
          <h5>
            {index === 0 ? "Outsource Team" : index === 1 ? "Dedicated Team" : "Project Done"}
            <span className="text_org float-end">{value}%</span>
          </h5>
          <ProgressBar
            now={progress[index]}
            style={{ width: "100%" }}
            className="animated-bar"
            aria-valuenow={progress[index]}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-label={`${index === 0 ? "Outsource Team" : index === 1 ? "Dedicated Team" : "Project Done"} Progress`}
          />
        </Layouts>
      ))}
    </div>
  );
};

export default AnimatedProgressBars;
