import React from 'react'
import event1 from '../images/event_1.webp';
import event2 from '../images/event_2.webp';
import event3 from '../images/event_3.webp';
import event4 from '../images/event_4.jpg';
import event5 from '../images/event_5.jpg';
import event6 from '../images/event_6.jpg';
import event7 from '../images/event_7.webp';
import event8 from '../images/event_8.jpg';
import event9 from '../images/event_9.jpg';
import event10 from '../images/event_10.jpg';
import event11 from '../images/event_11.jpg';
import event12 from '../images/event_12.jpg';
import event13 from '../images/event_13.jpg';
import event14 from '../images/event_14.jpg';
import event15 from '../images/event_15.JPG';
import event16 from '../images/event_16.JPG';
import event17 from '../images/event_17.JPG';
import event18 from '../images/event_18.JPG';
// import event19 from '../images/event_19.jpg';
// import event20 from '../images/event_20.jpg';



function HomeSection10() {
    return (
        <div className="section_10">
            <div className="container">
                <h2>ULTRAPRO EVENTS</h2>
            </div>
            <div className="container my-5" >
                <div className="marquee2">
                    <div className="marquee_inner w-100">
                        <div className="row align-items-start marquee-mobile">
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event1} alt='event-1' className="w-100" />
                                </div>
                                <div className="mt-3">
                                    <img src={event2} alt='event-2' className="w-100" />
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event3} alt='event-3' className="w-100" />

                                </div>
                                <div className="mt-3">
                                    <img src={event4} alt='event-4' className="w-100" />
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event5} alt='event-5' className="w-100" />
                                </div>
                                <div className="mt-3" >
                                    <img src={event6} alt='event-6' className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="marquee_inner w-100 ms-4">
                        <div className="row align-items-start marquee-mobile">
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event7} alt='event-7' className="w-100" />
                                </div>
                                <div className="mt-3">
                                    <img src={event8} alt='event-8' className="w-100" />
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event9} alt='event-9' className="w-100" />

                                </div>
                                <div className="mt-3">
                                    <img src={event10} alt='event-10' className="w-100" />
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event11} alt='event-11' className="w-100" />
                                </div>
                                <div className="mt-3" >
                                    <img src={event12} alt='event-12' className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="marquee_inner w-100 ms-4">
                        <div className="row align-items-start marquee-mobile">
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event13} alt='event-13' className="w-100" />
                                </div>
                                <div className="mt-3">
                                    <img src={event14} alt='event-14' className="w-100" />
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event15} alt='event-15' className="w-100" />

                                </div>
                                <div className="mt-3">
                                    <img src={event16} alt='event-16' className="w-100" />
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div>
                                    <img src={event17} alt='event-17' className="w-100" />
                                </div>
                                <div className="mt-3" >
                                    <img src={event18} alt='event-18' className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeSection10