import React from 'react'

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ImageSilder from './imageSilder';

function AboutUsSection5() {
    return (
        <div className="about_us_section_5">
            <div className="container">
                <div className="s5_header d-flex justify-content-between align-items-center">
                    <h2>Grand Event with Our Team</h2>
                    <Link to="../ourTeam">
                        <FontAwesomeIcon icon={faArrowRight} />
                        <span className="px-3">More Team</span>
                    </Link>
                </div>
                <ImageSilder/>
            </div>
        </div>
    )
}

export default AboutUsSection5