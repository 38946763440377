import React, { useEffect, useState } from 'react'
import ApexChart from './lineChart'
import CryptoJS from 'crypto-js'
import { config } from '../../../config/index';
import postRequest from '../../../Layout/apifetch';

function HomeSection3() {
    const [apiData, setApiData] = useState([]);
    const payload = {
        pair: 'UPRO_USDT'
    }
    const tradeInfo = config.tradeInfo_url;
    const securityKey = config.securityKey;
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await postRequest(tradeInfo, payload, {});
                if (response) {
                    const encryptedData = response.payload;
                    const decryptedData = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(securityKey), {
                        mode: CryptoJS.mode.ECB,
                        padding: CryptoJS.pad.Pkcs7
                    });
                    const result = decryptedData.toString(CryptoJS.enc.Utf8);
                    if (result) {
                        const parsedResult = JSON.parse(result);
                        setApiData(parsedResult?.data)
                    }
                }
            }
            catch(error){
                console.error(error)
            }
        }

        fetchData();

    }, []);

    return (
        <div className="section_3">
            <div className="container pt-4 pb-4">
                <div className="text-center">
                    <h3 >UPRO Market Performance</h3>
                    <p className="text-D6 sz16 poppins">Proudly designed to connect millions of value investors around the globe, Upro drives global financial innovation.</p>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-10" >
                        <ApexChart currentPrice={apiData?.lastPrice} />
                    </div>
                    <div className="col-md-2 chart_details mt-5 mt-md-0 text-center text-md-start row  p-0">
                        <div className="col-4 col-md-12">
                            <p>Current Price</p>
                            <p>{apiData?.lastPrice}</p>
                        </div>
                        <div className="col-4 col-md-12">
                            <p>24h High</p>
                            <p>{apiData?.high}</p>
                        </div>
                        <div className="col-4 col-md-12">
                            <p>24h Low</p>
                            <p>{apiData?.low}</p>
                        </div>

                    </div>
                </div>
                <div className="chart_container container" >
                    <div className="row rounded-3 main_div align-items-center" >
                        <div className="text-center col-md-4 sub_div">
                            <p>Total market cap</p>
                            <p>$289.89K</p>
                        </div>
                        <div className="text-center col-md-4 sub_div">
                            <p>Trading volume</p>
                            <p>$261.84M</p>
                        </div>
                        <div className="text-center col-md-4 sub_div">
                            <p>Total supply</p>
                            <p>1.09B UPRO</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeSection3