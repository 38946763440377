import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function FAQ() {
  const faqs = [
    {
      question: "What can I expect from attending the expo?",
      answer:
        "Attendees can expect a variety of interactive sessions, keynote speeches, panel discussions, and networking opportunities. You'll gain valuable insights from leading experts and explore cutting-edge crypto technologies.",
    },
    {
      question: "Do I need special software to attend the expo?",
      answer:
        "No special software is required. You can access the expo directly through your web browser on any device with an internet connection.",
    },
    {
      question: "Can I attend the expo from anywhere in the world?",
      answer:
        "Yes, the Online Crypto Expo is accessible worldwide. All you need is an internet connection to participate.",
    },
    {
      question: "Who should I contact if I need help or have more questions?",
      answer:
        "For any additional questions or support, please use the 'Contact Us' form on our website, and our team will be happy to assist you.",
    },
    {
      question: "What kind of exhibitors will be present at the expo?",
      answer:
        "The expo will feature a diverse range of exhibitors, including top cryptocurrency exchanges, blockchain projects, wallet providers, fintech companies, and more.",
    },
  ];

  return (
    <Container>
      <Row className="justify-content-center align-items-center text_whites pt-3 py-lg-5 py-md-0">
        <Col md={11}>
          <div className="d-flex flex-column align-items-center">
            <h5>Question & Answer</h5>
            <h4 data-aos="fade-up" data-aos-duration="1000">
              Frequently Asked Questions (FAQ)
            </h4>
            <p className="text-center browser">
              Browse our FAQs below. If you cannot find the answer you're
              looking for, please contact us.
            </p>
          </div>
          <div className="accordion my-2" id="accordionPanelsStayOpenExample">
            {faqs.map((faq, index) => (
              <div className="accordion-item mt-3 mx-md-5" key={index}>
                <h2
                  className="accordion-header"
                  id={`panelsStayOpen-heading${index}`}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-collapse${index}`}
                    aria-expanded={index === 0}
                    aria-controls={`panelsStayOpen-collapse${index}`}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-collapse${index}`}
                  className={`accordion-collapse collapse ${
                    index === 0 ? "show" : ""
                  }`}
                  aria-labelledby={`panelsStayOpen-heading${index}`}
                >
                  <div className="accordion-body">{faq.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FAQ;
