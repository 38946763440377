// import React from 'react'
import React, { useState, useEffect, useContext } from "react";
import "./nav.css";
import ultraprologo_dark from "./images/upro_logo.png";
import ultraprologo_light from "./images/logo_light.png";
import logo_1 from "./images/logo_1.png";
import logo_2 from "./images/logo_2.png";
import logo_3 from "./images/logo_3.png";
import logo_4 from "./images/logo_4.png";
import logo_5 from "./images/logo_5.png";
import logo_6 from "./images/logo_6.png";
// import logo_7 from "./images/logo_7.png ";
// import logo_8 from "./images/logo_8.png";
import drop_down_2_icon from "./images/honey.png";
import UBIT from "./images/UBIT.png";
import USDT from "./images/USDT.png";
import BUSD from "./images/BUSD.png";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faBars,
  faMoon,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { Offcanvas } from "react-bootstrap";
import { ThemeContext } from "../../Layout/ThemeContext";
import whitepaper from "./whitepaper/UltraPro+Blockchain+Whitepaper.pdf";

function Navbar() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const dropdown_1 = [
    {
      logo: logo_1,
      alt: "Ultrapro Explorer",
      title: "Ultrapro Explorer",
      description: "Track, explore, analyze blockchain effortlessly",
      link: "https://ultraproscan.io/",
    },
    {
      logo: logo_2,
      alt: "Ultrapro Exchange",
      title: "Ultrapro Exchange",
      description:
        "Seamless trading, advanced features, secure blockchain future",
      link: "https://www.ultraproex.com/",
    },
    {
      logo: logo_3,
      alt: "Canopus GTX Wallet",
      title: "Canopus GTX Wallet",
      description: "Secure, trusted companion for managing your crypto assets",
      link: "https://www.amazon.com/dp/B0CLKVQX1Q",
    },
    {
      logo: logo_4,
      alt: "UPRO Payments",
      title: "UPRO Payments",
      description:
        "Quick, secure transactions, built for the future of finance",
      link: "https://upropayments.com/",
    },
    {
      logo: logo_5,
      alt: "DeFi",
      title: "DeFi",
      description:
        "Decentralized finance for trading, lending, and borrowing—no intermediaries",
      link: "https://eenaswap.finance/",
    },
    {
      logo: logo_6,
      alt: "UPRO NFT",
      title: "UPRO NFT",
      description:
        "Buy, sell, and trade unique digital collectibles on our secure platform",
      link: "https://upronft.com/",
    },
    // {
    //   logo: logo_7,
    //   alt: "UPRO Staking",
    //   title: "UPRO Staking",
    //   description: "Stake assets and earn rewards while supporting the network",
    //   link: "https://canopro.com/stake",
    // },
    // {
    //   logo: logo_8,
    //   alt: "PRO20 USDT",
    //   title: "PRO20 USDT",
    //   description:
    //     "Your reliable stablecoin for effortless transactions in the digital economy",
    //   link: "https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers",
    // },
  ];

  const dropdown_2 = [
    {
      logo: UBIT,
      alt: "UBIT",
      title: "UBIT",
      description:
        "Your gateway to seamless transactions and low fees in the blockchain world",
      link: "https://ultraproscan.io/token/0x1F5712B75B01FF9bD337811b56E3c9f1257CEa86/token-transfers",
    },
    {
      logo: drop_down_2_icon,
      alt: "Honey",
      title: "Honey",
      description: "Get rewards and offers with Honey!",
      link: "https://ultraproscan.io/token/0x229268FEeaC6C6D620E09F09825a6f3CdA9ec17a/token-transfers",
    },
    {
      logo: USDT,
      alt: "USDT",
      title: "USDT",
      description:
        "Trade effortlessly with USDT Stable, reliable for all your crypto transactions",
      link: "https://ultraproscan.io/token/0x0F1174467F0661659cd7a1474Baf8Fbf9bEb7EB1/token-transfers",
    },
    {
      logo: BUSD,
      alt: "BUSD",
      title: "BUSD",
      description:
        "BUSD provides stability—experience fast transactions and low fees",
      link: "https://ultraproscan.io/token/0x229268FEeaC6C6D620E09F09825a6f3CdA9ec17a/token-transfers",
    },
  ];

  const [isMobile, setIsMobile] = useState(false);
  const [showDropDown, setShowDropDown] = useState(null);
  const [showMobileCanvas, setShowMobileCanvas] = useState(false);
  const { theme, handleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowDropDown = (value) => {
    if (showDropDown === value) {
      setShowDropDown(null);
    } else {
      setShowDropDown(value);
    }
  };

  const handleMobileMenuOpen = () => setShowMobileCanvas(true);
  const handleMobileMenuClose = () => setShowMobileCanvas(false);

  return (
    <div className="header">
      <div className="container">
        <nav
          className="navbar navbar-expand-lg justify-content-between"
          data-bs-theme="dark"
        >
          <Link to={"/"}>
            <img
              src={theme === "dark" ? ultraprologo_dark : ultraprologo_light}
              alt="Ultrapro-logo"
              className="img-fluid ultrapro-logo"
            />
          </Link>

          {isMobile ? (
            <div className="d-flex align-items-center">
              {theme === "light" ? (
                <FontAwesomeIcon
                  icon={faMoon}
                  className="moon-icon"
                  onClick={() => handleTheme("dark")}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faSun}
                  className="sun-icon"
                  onClick={() => handleTheme("light")}
                />
              )}

              <button className="btn menu-btn" onClick={handleMobileMenuOpen}>
                <FontAwesomeIcon icon={faBars} className="menu-icon" />
              </button>
              <Offcanvas
                show={showMobileCanvas}
                onHide={handleMobileMenuClose}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <img
                    src={
                      theme === "dark" ? ultraprologo_dark : ultraprologo_light
                    }
                    alt="Ultrapro-logo"
                    className="img-fluid ultrapro-logo"
                  />
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mobile-nav-list">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${isActive("/")}`}
                        to="/"
                        onClick={handleMobileMenuClose}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <button
                        className="nav-link dropdown-btn"
                        onClick={() => handleShowDropDown("ecosystem")}
                      >
                        Ecosystem{" "}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`${
                            showDropDown === "ecosystem" ? "rotate-icon" : ""
                          }`}
                        />
                      </button>

                      <div
                        className={`row mobile-dropdown ${
                          showDropDown === "ecosystem" ? "open" : ""
                        }`}
                      >
                        {dropdown_1.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center gap-2 dropdown_item col-md-12 mt-4"
                          >
                            <img src={item.logo} height="64" alt={item.alt} />
                            <Link to={item.link} target="_blank">
                              <label>{item.title}</label>
                              <label>{item.description}</label>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${isActive("/aboutUs")}`}
                        to="../aboutUs"
                        onClick={handleMobileMenuClose}
                      >
                        About us
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        className={`nav-link ${isActive("/blog")}`}
                        to="../blog"
                        onClick={handleMobileMenuClose}
                      >
                        Blog
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <button
                        className="nav-link dropdown-btn"
                        onClick={() => handleShowDropDown("token")}
                      >
                        Token{" "}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`${
                            showDropDown === "token" ? "rotate-icon" : ""
                          }`}
                        />
                      </button>

                      <div
                        className={`row mobile-dropdown ${
                          showDropDown === "token" ? "open" : ""
                        }`}
                      >
                        {dropdown_2.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center gap-2 dropdown_item mt-4"
                          >
                            <img
                              src={item.logo}
                              alt={item.alt}
                              height="63"
                              width="63"
                            />
                            <Link
                              to={item.link}
                              className="d-flex flex-column text-decoration-none"
                              target="_blank"
                            >
                              <label>{item.title}</label>
                              <label>{item.description}</label>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={`nav-link ${isActive("/expo")}`}
                        to="../expo"
                        onClick={handleMobileMenuClose}
                      >
                        Expo
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${isActive("/enhanced")}`}
                        to="../enhanced"
                        onClick={handleMobileMenuClose}
                      >
                        Enhanced-Enterprises
                      </Link>
                    </li>
                  </ul>
                  <div className="d-flex action gap-3 align-items-center ms-auto mobile-act-btn justify-content-center">
                    <Link className="col-6 text-center whitepaper" to="#">
                      Whitepaper
                    </Link>
                    <Link
                      className="col-6 text-center contact"
                      to="../contact"
                      onClick={handleMobileMenuClose}
                    >
                      Contact us
                    </Link>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          ) : (
            //Desktop Menu
            <>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${isActive("/")}`}
                      aria-current="page"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown position-relative">
                      <Link className="nav-link" to="#">
                        Ecosystem <FontAwesomeIcon icon={faChevronDown} />
                      </Link>
                      <div style={{ paddingTop: "15px", position: "absolute" }}>
                        <div className="dropdown_content row">
                          {dropdown_1.map((item, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2 dropdown_item col-md-6 mt-4"
                            >
                              <img src={item.logo} height="64" alt={item.alt} />
                              <Link to={item.link} target="_blank">
                                <label>{item.title}</label>
                                <label>{item.description}</label>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${isActive("/aboutUs")}`}
                      to="../aboutUs"
                    >
                      About us
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className={`nav-link ${isActive("/blog")}`}
                      to="../blog"
                    >
                      Blog
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <div className="dropdown_2 position-relative">
                      <Link className="nav-link" to="#">
                        Token <FontAwesomeIcon icon={faChevronDown} />
                      </Link>
                      <div style={{ paddingTop: "10px", position: "absolute" }}>
                        <div className="dropdown_content_2 row">
                          {dropdown_2.map((item, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2 dropdown_item mt-4"
                            >
                              <img
                                src={item.logo}
                                alt={item.alt}
                                height="63"
                                width="63"
                              />
                              <Link to={item.link} target="_blank">
                                <label>{item.title}</label>
                                <label>{item.description}</label>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${isActive("/expo")}`}
                      to="../expo"
                    >
                      Expo
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${isActive("/enhanced")}`}
                      to="../enhanced"
                    >
                      Enhanced-Enterprises
                    </Link>
                  </li>
                </ul>

                <div className="d-flex action gap-3 align-items-center ms-auto">
                  {theme === "light" ? (
                    <FontAwesomeIcon
                      icon={faMoon}
                      className="moon-icon"
                      onClick={() => handleTheme("dark")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSun}
                      className="sun-icon"
                      onClick={() => handleTheme("light")}
                    />
                  )}
                  <Link className="whitepaper" to={whitepaper} target="_blank">
                    Whitepaper
                  </Link>
                  <Link className="contact" to="../contact">
                    Contact us
                  </Link>
                </div>
              </div>
            </>
          )}
        </nav>
      </div>
    </div>
  );
}
export default Navbar;
