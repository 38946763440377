import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layouts from "../../Layout/Childrens";
import MainLayOut from "../../Layout/MainLayOut";
import Buttons from "./components/Buttons";
import Images from "./components/Images";
import template from "./images/template-img.png";
import expo from "./images/expo.png";
import tier1 from "./images/tier-icon_1.png";
import composs from "./images/composs-img.png";
import world from "./images/world-img.png";
import Objdata from "./components/objDatas/Objdata";
import mobile from "./images/mobile-img.png";
import empoly1 from "./images/empoly-1.png";
import empoly2 from "./images/empoly-2.png";
import empoly3 from "./images/empoly-3.png";
// import collection from "./images/client-collection.png";
import files from "./images/files.png";
import bitcoin from "./images/bit-coim.png";
import coinimg from "./images/coin-img.png";
import exchange from "./images/exchange.png";
import Swipers from "./components/swiper/Swipers";
import Accodin from "./components/Accodin";
import AnimatedProgressBar from "./components/AnimatedProgressBar";
import "./style.css";
import { Helmet } from "react-helmet-async";
import Topcrypto from "./components/Topcrypto";
import ExpoForm from "./components/expo_form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
function Expo() {

  return (
    <>
      <Helmet>
        <title>The World’s Biggest Online Crypto Expo</title>
        <meta
          name="description"
          content="A groundbreaking crypto online expo with 2.5 million participants, connecting the world to blockchain’s future without limits"
        ></meta>
        <meta
          name="keywords"
          content="Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer1 blockchain, EVM Compatible, PoA Consensus Algorithm, blockchain expo world 2024,
                    What is Blockchain Expo?,Crypto Online Expo,What is the biggest crypto Expo?,Best crypto online expo,Crypto online expo india,What is the largest crypto exchange?,crypto expo india,
                   crypto expo india,top crypto conferences"
        ></meta>
      </Helmet>

      <MainLayOut styles="sec_1  py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={11}>
              <Row className=" justify-content-center align-items-center">
                <Col md={6} lg={7}>
                  <Layouts styles="online_crypto">
                    <h6 className="font_inter fw600 text_whites text-uppercase">
                      Crypto Online Expo
                    </h6>
                    <h1 className="text-capitalize  font_inter fw600">
                      The World’s Biggest Online Crypto Expo!
                    </h1>
                    <p>
                      Be part of the largest virtual crypto gathering in
                      history! This online crypto expo unites enthusiasts from
                      around the globe to network, exchange ideas, and drive the
                      future of the crypto revolution from anywhere in the
                      world.
                    </p>
                  </Layouts>
                </Col>
                <Col md={6} lg={5}>
                  <Layouts styles="form_1">
                    <ExpoForm/>
                  </Layouts>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec_2">
        <Container>
          <Row
            className="justify-content-center text-center "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Col lg={11}>
              <Row className="justify-content-center align-items-center">
                <Col
                  xs={6}
                  lg={3}
                  className="count d-flex flex-column align-items-center"
                >
                  <h3 className="text-white font_inter fw600">10M+</h3>
                  <h6 className="text-white font_inter fw400">
                    User’s worldwide
                  </h6>
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="count d-flex flex-column align-items-center"
                >
                  <h3 className="text-white font_inter fw600">$15M+</h3>
                  <h6 className="text-white font_inter fw400">
                    Daily Average Trading Volume (USD)
                  </h6>
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="count d-flex flex-column align-items-center"
                >
                  <h3 className="text-white font_inter fw600">$24/7</h3>
                  <h6 className="text-white font_inter fw400">
                    Customer Support
                  </h6>
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="count d-flex flex-column align-items-center"
                >
                  <h3 className="text-white font_inter fw600">230+</h3>
                  <h6 className="text-white font_inter fw400">Countries</h6>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec_3 text_whites">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={11}>
              <Row className="justify-content-center align-items-center">
                <Col sm={4} lg={6}>
                  <Images styles="img-fluid" link={template} />
                </Col>
                <Col sm={8} lg={6} className="about_us">
                  <h4 className="text-uppercase text_org mt-3 ">About Us</h4>
                  <h3>
                    Welcome to the
                    <span className="text_org"> World’s First Crypto</span>{" "}
                    Online Expo
                  </h3>
                  <p>
                    We are thrilled to host the world’s first crypto online
                    expo, a pioneering event with 2.5 million participants.
                    Unlike traditional physical events, where attendance is
                    limited, this online expo ensures that everyone can benefit,
                    no matter where they are.
                  </p>
                  <Row>
                    <Col xs={12} sm={12} lg={6} className="prog_1">
                      <Layouts>
                        <AnimatedProgressBar />
                      </Layouts>
                      <Layouts styles="prograss_1 pt-lg-3">
                        <Link className="btn-actions" onClick={()=> {window.scrollTo(0,0);}}>
                          LEARN MORE <FontAwesomeIcon icon={faArrowRight}/>
                        </Link>
                      </Layouts>
                    </Col>
                    <Col className="d-none d-lg-block" xs={5} sm={4} lg={6}>
                      <Images
                        styles="img-fluid"
                        link={expo}
                        alt="world first crypto online expo"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec_4 my-3">
        <Container>
          <Layouts styles="d-flex justify-content-center">
            <Col lg={10}>
              <Layouts>
                <h4 className="text-white w-100 font_inter fw600 d-xl-flex justify-content-between gap-3 gap-lg-5">
                  <span>Top 25 Crypto Exchange Partnerships</span>
                  <Buttons
                    styles="btn btn_org mt-md-3 text_whites float-md-end ms-md-3"
                    content=" See all supported exchange"
                  />
                </h4>
              </Layouts>
              <h6 className="font_inter fw400 text-white ps-md-2">
                Ultrapro, Binance, Tron, Solana and more…
              </h6>
              <Objdata />
            </Col>
          </Layouts>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec_5 font_inter">
        <div className="container cm_shd1">
          <p className="bg_org text-center">www.worldcryptoanalysis.com</p>
          <h2
            className="text-center text_whites"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            SAVE <span className="bg_org">CRYPTO</span> COMMUNITY
          </h2>
          <div className="row mx-md-5 justify-content-center align-items-center tier">
            <div className="col-md-3 text_whites">
              <Images link={tier1} styles="img-fluid" />
              <h2>TIER 1</h2>
              <ul>
                <li>
                  <span className="bg_org">Criteria:</span> Must not list coins
                  purely for profit. They prioritize listing trustworthy coins
                  that benefit the user community.
                </li>
                <li className="py-2">
                  <span className="bg_org">Requirements:</span> Exchange owners
                  must complete KYC and be publicly visible to guarantee the
                  security of public funds.
                </li>
              </ul>
            </div>
            <div className="col-md-6 mobile_animation">
              <Images link={mobile} styles="img-fluid" />
            </div>
            <div className="col-md-3">
              <div className="text_whites tire_2 d-flex flex-column align-items-start align-items-md-end">
                <Images link={composs} styles="img-fluid" />
                <h2>TIER 2</h2>
                <p>
                  <span className="bg_org">Focus:</span> on High trading volumes
                  and growing user bases. These exchanges must open local
                  offices in regions where their user base is growing to provide
                  better support.
                </p>
              </div>
              <div className="text_whites tire_2 d-flex flex-column align-items-start align-items-md-end">
                <Images link={world} styles="img-fluid" />
                <h2>TIER 3</h2>

                <p>
                  <span className="bg_org">Focus:</span> Emerging exchanges that
                  show promise and meet essential transparency standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </MainLayOut>
      <MainLayOut styles="sec_6">
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <h1 className="text-center ">How we work</h1>
              <Row className="justify-content-center align-items-center">
                <Col md={4}>
                  <Layouts styles="d-flex justify-content-center flex-column align-items-center employ ">
                    <Images link={empoly1} styles="img-fluid" />
                    <h5>Effortless Participation</h5>
                    <p>
                      Join our online expo easily from anywhere. Register to
                      access live sessions, interactive booths, and networking
                      opportunities, all in a user-friendly virtual environment.
                    </p>
                  </Layouts>
                </Col>
                <Col md={4}>
                  <Layouts styles="d-flex justify-content-center flex-column align-items-center  employ">
                    <Images link={empoly2} styles="img-fluid" />
                    <h5>Insightful Learning</h5>
                    <p>
                      Engage with expert panels, workshops, and keynotes to gain
                      valuable crypto knowledge. Connect with top exchanges and
                      industry leaders to stay ahead of the trends.
                    </p>
                  </Layouts>
                </Col>
                <Col md={4}>
                  <Layouts styles="d-flex justify-content-center flex-column align-items-center employ">
                    <Images link={empoly3} styles="img-fluid" />
                    <h5>Instant Support</h5>
                    <p>
                      Receive real-time assistance and updates throughout the
                      expo. Our dedicated support team is available 24/7 to
                      ensure a smooth and enjoyable experience.
                    </p>
                  </Layouts>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec_7">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={11}>
              <Row className="align-items-center justify-content-center">
                <Col lg={8}>
                  <Layouts styles="Commitment ">
                    <h5
                      className="fw700 bg_org font_inter"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      UltraPro Exchange's{" "}
                    </h5>
                    <h5 className="fw700 font_inter text_whites">
                      Vision and Commitment
                    </h5>
                    <p className="py-lg-2 bg">
                      At this expo, Ultrapro Exchange reaffirms its commitment
                      to the highest standards of user safety, transparency, and
                      trust.
                    </p>
                    <Link className="btn-actions" onClick={()=> {window.scrollTo(0,0);}}>
                      Lets Talk Now <FontAwesomeIcon icon={faArrowRight}/>
                    </Link>
                  </Layouts>
                </Col>
                <Col lg={4}>
                  <Layouts styles="Genuine">
                    <h3 className="fw700">Genuine Listings</h3>
                    <p className="text-black">
                      We feature only coins that have been thoroughly vetted to
                      ensure they benefit our users.
                    </p>
                    <div className="underline my-2"></div>
                    <h3>Local Support</h3>
                    <p className="text-black">
                      As our user base expands, we will establish local offices
                      to enhance support and safety.
                    </p>
                    <div className="underline mb-3"></div>
                    <h3>Continuous Evaluation</h3>
                    <p className="text-black">
                      We consistently review coins and tokens to ensure they
                      adhere to our rigorous standards.
                    </p>
                  </Layouts>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="crypto_tier">
        <Container fluid>
          <Topcrypto />
        </Container>
      </MainLayOut>
      <MainLayOut styles="cm_chead1">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={11}>
              <h4 className="font_inter fw500 text-uppercase text-center text-md-start text_whites">
                Coins and tokens
              </h4>
              <h3 className="fw600 font_inter text_whites">
                In-Depth Exploration of <span className="bg_org">Top 200</span>{" "}
                Coins and Tokens
              </h3>
              <h4 className="fw400 text_whites">
                We’ve selected the top 200 coins and tokens, focusing on those
                with strong foundations and clear ownership. This list
                highlights the most dependable and promising assets in the
                crypto market.
              </h4>
              <Layouts styles="cm_ctbx1">
                <h6 className="font_inter fw600 text_whites">Coins:</h6>
                <ul className="cm_ctlis1">
                  <li>
                    <span className="txb1 ">Criteria: </span>
                    Coins are supported by a blockchain, with known ownership
                    and transparent goals.
                  </li>
                  <li>
                    <span className="txb1">Requirements: </span>
                    Each coin must have a clear purpose, fixed supply, and
                    disclose who controls the project.
                  </li>
                </ul>
              </Layouts>
              <Layouts styles="cm_ctbx1">
                <h6 className="font_inter fw600 text_whites">Tokens:</h6>
                <ul className="cm_ctlis1">
                  <li>
                    <span className="txb1 ">Criteria: </span>
                    Tokens must be audited, with clear ownership, and
                    no minting privileges. Token owners must complete KYC to
                    ensure transparent ownership. The project must have a public
                    website and disclose its background.
                  </li>
                  <li>
                    <span className="txb1">Purpose: </span>
                    Ensuring that only projects with legitimate goals
                    and transparent operations are highlighted.
                  </li>
                </ul>
              </Layouts>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec_10 pt-2 pt-sm-5">
        <Container>
          <Row className=" justify-content-center align-items-center">
            <Col lg={11}>
              <Row className=" justify-content-center align-items-center">
                <Col lg={5}>
                  <Layouts styles="edu_sc text_whites">
                    <h5 className="text-uppercase font_inter bg_org fw700">
                      Educating Users
                    </h5>
                    <h3 className="font_inter fw600">
                      Your Guide to Crypto Investments
                    </h3>
                    <p className="showcasing font_inter fw400">
                      This expo isn’t just about showcasing exchanges and coins;
                      it’s about empowering you with the knowledge you need to
                      make informed decisions in crypto.
                    </p>
                  </Layouts>
                </Col>
                <Col lg={7}>
                  <Layouts>
                    <Row className="text_whites ">
                      <Col md={6}>
                        <Layouts styles="emt2 ">
                          <Layouts
                            styles="edu_bx1"
                            aos="fade-right"
                            duration={1000}
                          >
                            <h5 className="font_inter fw400 text_whites">
                              Investing in Crypto
                            </h5>
                            <p className="fw400 font_serif">
                              Learn how to select the right coins and tokens for
                              investment.
                            </p>
                            <Images
                              link={files}
                              styles="img-fluid postion_types"
                            />
                          </Layouts>
                          <Layouts
                            styles="edu_bx1"
                            aos="fade-right"
                            duration={1000}
                          >
                            <h5>Trading Best Practices</h5>
                            <p>
                              Discover secure methods and platforms for trading.
                            </p>
                            <Images
                              link={bitcoin}
                              styles="img-fluid postion_types"
                            />
                          </Layouts>
                        </Layouts>
                      </Col>
                      <Col md={6}>
                        <Layouts>
                          <Layouts
                            styles="edu_bx1"
                            aos="fade-right"
                            duration={1000}
                          >
                            <h5 className="font_inter fw400 text_whites">
                              Staking Opportunities
                            </h5>
                            <p className="fw400 font_serif">
                              Understand which coins offer the best staking
                              rewards and how to maximize them.
                            </p>
                            <Images
                              link={coinimg}
                              styles="img-fluid postion_types"
                            />
                          </Layouts>
                          <Layouts
                            styles="edu_bx1"
                            aos="fade-right"
                            duration={1000}
                          >
                            <h5>Exchange Evaluation</h5>
                            <p>
                              Learn how to assess the safety and reliability of
                              various exchanges.
                            </p>
                            <Images
                              link={exchange}
                              styles="img-fluid postion_types"
                            />
                          </Layouts>
                        </Layouts>
                      </Col>
                    </Row>
                  </Layouts>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec_11 my-3 my-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={11}>
              <h2 className="text-center text-md-start mx-md-5 fw600 font_inter text_whites">
                Testimonials
              </h2>
              <Layouts styles="testimonials px-md-5">
                <Swipers />
              </Layouts>
            </Col>
          </Row>
        </Container>
      </MainLayOut>
      <MainLayOut styles="sec-12 accordions_page py-2 py-sm-5">
        <Accodin />
      </MainLayOut>
    </>
  );
}

export default Expo;
