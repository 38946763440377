import React, { useContext } from "react";
import "./style.css";
import ultraprologo_dark from "../nav/images/upro_logo.png";
import ultraprologo_light from "../nav/images/logo_light.png";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../Layout/ThemeContext";
import whitepaper from "../nav/whitepaper/UltraPro+Blockchain+Whitepaper.pdf";

function Footer() {
  const section_1 = [
    {
      name: "UPRO Explorer",
      link: "https://ultraproscan.io/"
    },
    {
      name: "Exchange",
      link: "https://www.ultraproex.com/"
    },
    {
      name: "Wallet",
      link: "https://www.amazon.com/dp/B0CLKVQX1Q"
    },
    {
      name: "Defi",
      link: "https://eenaswap.finance/"
    },
    {
      name: "Payments",
      link: "https://upropayments.com/"
    },
    {
      name: "NFT",
      link: "https://upronft.com/"
    }
  ];
  const section_2 = [
    {
      name: "Expo",
      link: "../expo",
    },
    {
      name: "Whitepaper",
      link: whitepaper,
    },
    // {
    //   name: "Token",
    //   link: "https://latoken.com/",
    // },
    {
      name: "Staking",
      link: "https://www.ultraproex.com/staking/"
    },
    {
      name: "Events",
      link: "/enhanced",
    },
  ];
  const section_3 = [
    {
      name: "Telegram",
      link: "https://t.me/ultrapro_announcement",
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/ultraproupdates/",
    },
    {
      name: "Facebook",
      link: "https://www.facebook.com/ultraproupdates/",
    },
    {
      name: "Youtube",
      link: "https://www.youtube.com/@ultraproupdates",
    },
    {
      name: "X",
      link: "https://twitter.com/Ultraproupdates",
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/ultrapro-blockchain/",
    },
    {
      name: "Medium",
      link: "https://medium.com/@ultraproblockchain",
    },
  ];
  const section_4 = [
    {
      name: "Privacy Policy",
      link: "../privacyPolicy",
    },
    {
      name: "Terms of Use",
      link: "../termsUse",
    },
    {
      name: "Contact Us",
      link: "../contact",
    },
    {
      name: "Our Team",
      link: "../ourTeam",
    },
  ];

  const { theme } = useContext(ThemeContext);

  return (
    <footer>
      <div className="container">
        <div className="row footer pt-4 pb-4 my-4">
          <div className="col-md-3 col-12">
            <div className="d-flex gap-2 align-items-center logo">
              <Link to="/" target="_blank">
                <img
                  src={
                    theme === "dark" ? ultraprologo_dark : ultraprologo_light
                  }
                  alt="logo"
                  className="img-fluid"
                />
              </Link>
            </div>
            <p>
              We envision a world where users have complete control over their
              data, identities, and digital assets.
            </p>
          </div>
          <div className="col-md-9 col-12">
            <div className="row footer_action">
              <div className="col-md-4 col-lg-3 col-6">
                <label>Explore</label>
                <ul>
                  {section_1.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link} target="_blank">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-4 col-lg-3 col-6">
                <label>Build</label>
                <ul>
                  {section_2.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={item.link}
                        target={item.name === "Expo" || item.name ===  "Events" ? "_self" : "_blank"}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-4 col-lg-3 col-6">
                <label>Community</label>
                <ul>
                  {section_3.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link} target="_blnak">{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-4 col-lg-3 col-6">
                <label>About</label>
                <ul>
                  {section_4.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="copy_rights">
        &copy; 2024 ultrapro blockchain.info.All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
