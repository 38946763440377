import React from "react";
import { Link } from "react-router-dom";
import blockchain from '../images/blockchain.png';
import wallet from '../images/wallet.png';
import explorer from '../images/explorer.png';
import payments from '../images/payments.png';
import nft from '../images/nft.png';
import defi from '../images/defi.png';
import exchange from '../images/exchange.png';
import probinar from '../images/probinar_1.png';
import iphone from '../images/iphone.png';

function HomeSection4() {

  return (
    
    <div className="section_4 container" >
      <h2>Our EcoSystem</h2>
      
        <div className="col-md-12">
          <div className="ph_main">
            <div className="ph_lef">
              <ul className="icop_lis1">
                <li>
                  <Link to="/">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={blockchain} className="img-fluid" alt="blockchain"/>
                    </span>
                    Blockchain
                  </Link>
                </li>
                <li>
                  <Link to="https://www.amazon.com/dp/B0CLKVQX1Q" target="_blank">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={wallet} className="img-fluid" alt="wallet"/>
                    </span>
                    Wallet
                  </Link>
                </li>
                <li>
                  <Link to="https://ultraproscan.io/" target="_blank">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={explorer} className="img-fluid" alt="explorer"/>
                    </span>
                    Explorer
                  </Link>
                </li>
                <li>
                  <Link to="https://upropayments.com/" target="_blank">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={payments} className="img-fluid" alt="payments"/>
                    </span>
                    Payments
                  </Link>
                </li>
              </ul>
            </div>
            <div className="phone-container">

              <div className="wave wave1"></div>
              <div className="wave wave2"></div>
              <div className="wave wave3"></div>
              <div className="wave wave4"></div>
              <div className="wave wave5"></div>
              <div className="wave wave6"></div>

              <div className="phone">
                <img loading="lazy" src={iphone} className="img-fluid" alt="iphone"/>
              </div>
            </div>
            <div className="ph_rig">
              <ul className="icop_lis1">
                <li>
                  <Link to="https://upronft.com/" target="_blank">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={nft} className="img-fluid" alt="nft"/>
                    </span>
                    NFT
                  </Link>
                </li>
                <li>
                  <Link to="https://eenaswap.finance/" target="_blank">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={defi} className="img-fluid" alt="defi"/>
                    </span>
                    DeFi
                  </Link>
                </li>
                <li>
                  <Link to="https://www.ultraproex.com/" target="_blank">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={exchange} className="img-fluid" alt="exchange"/>
                    </span>
                    Exchange
                  </Link>
                </li>
                <li>
                  <Link to="https://www.probinar.in/" target="_blank">
                    <span className="pico1 d-flex justify-content-center align-items-center">
                      <img loading="lazy" src={probinar} className="img-fluid" alt="probinar"/>
                    </span>
                    Probinar
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

  
    </div>
  );
}

export default HomeSection4;
