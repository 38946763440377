import React,{useState} from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Button } from 'react-bootstrap';
import Layouts from '../../../Layout/Childrens';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postRequest from '../../../Layout/apifetch';
import { config } from '../../../config';



function ExpoForm() {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [disable, setdisable] = useState(false);


    const onSubmit = async (data) => {
        setdisable(true);
        const { name, email, whatsappNum, interest } = data;
        const message = `Interested in ${interest}`;
        const subject = `New Request from ${data.name}`
        const osType = 0;
        const payload = { name, email, subject, osType, message };
        const url = config.contact_url;
        const headers = {}
        try {
            const result = await postRequest(url, payload,headers);
            if (result) {
                toast.success('Email Sent Successfully', { theme: 'colored' })
                reset();
                setdisable(false);
            }
        }
        catch(error){
            console.error(error)
        }
    };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <h5 className="text_whites">ONLINE CRYPTO EXPO PASS</h5>
                <Form.Group controlId="formBasicName" className='formGroup'>
                    <Form.Control
                        type="text"
                        placeholder="Your Name"
                        {...register('name', { required: 'Name is required' })}
                    />
                    {errors.name && <span style={{ color: 'red' }}>{errors.name.message}</span>}
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className='formGroup'>
                    <Form.Control
                        type="email"
                        placeholder="Your Email"
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: 'Invalid email address'
                            }
                        })}
                    />
                    {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
                </Form.Group>
                <Form.Group
                    className="my-3 mb-md-5 formGroup"
                    controlId="formBasicwhatsappNum"
                >
                    <Form.Control
                        type="text"
                        placeholder="Whatsapp Number"
                        {...register('whatsappNum', {
                            required: 'Whatsapp Number is required',
                            pattern: {
                                value: /^[0-9]+$/,
                                message: 'Whatsapp Number must be a valid numeric value'
                            },
                            minLength: {
                                value: 10,
                                message: 'Whatsapp Number must be at least 10 digits'
                            },
                            maxLength: {
                                value: 10,
                                message: 'Whatsapp Number must be 10 digits'
                            }
                        })}
                    />
                    {errors.whatsappNum && <span style={{ color: 'red' }}>{errors.whatsappNum.message}</span>}
                </Form.Group>
                <Row>
                    <Layouts styles="col-sm-6">
                        <Form.Group className="mb-3 formGroup" controlId="formBasicage">
                            <Form.Control
                                type="number"
                                placeholder="Your Age"
                                name="age"
                                className="p-3"
                                {...register('age', {
                                    required: 'Age is required',
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Age must be a number'
                                    },
                                    min: { value: 18, message: 'You must be at least 18' }
                                })}
                            />
                            {errors.age && <span style={{ color: 'red' }}>{errors.age.message}</span>}
                        </Form.Group>
                    </Layouts>
                    <Layouts styles="col-sm-6">
                        <Form.Select {...register('interest', { required: 'Interest is required' })}>
                            <option>Choose your interest</option>
                            <option> Both </option>
                            <option> Crypto Trading </option>
                            <option> Learning Blockchain </option>
                        </Form.Select>
                        {errors.interest && <span style={{ color: 'red' }}>{errors.interest.message}</span>}
                    </Layouts>
                </Row>
                <Button
                    variant="primary"
                    className="mt-3 mt-md-0 w-100 text_whites btn btn-org"
                    type="submit"
                    disabled={disable}
                    style={{cursor: disable ? 'not-allowed': 'pointer'}}
                >
                   {disable ? "Sending..." : "Submit"}
                </Button>
            </Form>
            <ToastContainer />
        </>

    )
}

export default ExpoForm