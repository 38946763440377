import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import Images from "../Images";
import client1 from "./assets/client-1.png";
import client2 from "./assets/client-2.png";

const testimonials = [
  {
    feedback:
      "“An incredible experience! The Online Crypto Expo brought together top minds in the industry. I learned so much and made valuable connections!”",
    name: "John D",
    role: "Crypto Enthusiast",
    image: client1,
  },
  {
    feedback:
      "“A truly global event! The expo allowed me to network with crypto experts from all over the world, all from the comfort of my home.”",
    name: "Janat Onagah",
    role: "@Janat_Onagah",
    image: client1,
  },
  {
    feedback:
      "“Attending the Online Crypto Expo was the best decision I've made this year. The sessions were informative, and the networking opportunities were endless.”",
    name: "Thanishta K",
    role: "Fintech Entrepreneur",
    image: client2,
  },
  {
    feedback:
      "“The insights shared at the expo were invaluable. I feel more confident about my investments and strategies going forward.”",
    name: "Joel W",
    role: "Investor",
    image: client1,
  },
];

function Swipers() {
  return (
    <Swiper
      spaceBetween={30}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      pagination={{
        clickable: true,
      }}
      loop={true}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
      modules={[Autoplay]}
      className="mySwiper"
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div className="testimonials-card">
            <div className="feedback">
              <p>{testimonial.feedback}</p>
            </div>
            <div className="d-flex align-items-center gap-3 mt-4">
              <Images
                link={testimonial.image}
                alt={`Image of ${testimonial.name}`}
              />
              <div className="user-id">
                <h6 className="text_whites">{testimonial.name}</h6>
                <p>{testimonial.role}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Swipers;
