import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

function Events() {


    const [count, setCount] = useState(0);
    const [startValue, setStartValue] = useState(0);
    const [endValue, setEndValue] = useState(5);

    useEffect(() => {
        if (count >= endValue) {
            setStartValue(startValue + 5);
            setEndValue(endValue + 5);
        } else if (count < startValue) {
            setStartValue(startValue - 5);
            setEndValue(endValue - 5);
        }
    }, [count,startValue,endValue])

    const Arrowup = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };
    const HandleClick = (index) => {
        setCount(index);
    };
    const Arrowdown = () => {
        if (count < tabContent.length - 1) {
            setCount(count + 1);
        }
    };

    const btn_tab = [
        {
            id: 'diwali_celebration_2024',
            btn: 'Diwali Celebration 2024'
        },
        {
            id: 'ultrapro_exchange_launch',
            btn: 'Ultrapro Exchange Launch'
        },
        {
            id: 'probinar_office_tour',
            btn: 'Probinar Office Tour'
        },
        {
            id: 'probinar_launch',
            btn: 'Probinar Launch'
        },
        {
            id: 'premium_staking_club',
            btn: 'Premium Staking Club'
        },
        {
            id: '3D_Animated_tour',
            btn: '3D Animated Tour of GE Bikes'
        },
        {
            id: 'TapyMax_celebration',
            btn: 'TapyMax Celebration – Honoring GEBikes Success'
        },
        {
            id: 'ultraPro_launches_GEBikes',
            btn: 'UltraPro Launches GEBikes Production in India'
        },
        {
            id: 'celebrating_5M',
            btn: 'Celebrating 5 Million Play Store Users on Ultrapro'
        },
        {
            id: 'play_Store_success',
            btn: 'Play Store Success: 1 Million Downloads on Ultrapro!'
        }
    ]


    const tabContent = [
        {
            id: 'diwali_celebration_2024',
            link: 'OMWCQb8xgSw?si=iTvHnf5WTRUJPyjB'
        },
        {
            id: 'ultrapro_exchange_launch',
            link: 'vXgIPB9UpAM?si=F8-IzBZdrfWLS3JJ'
        },
        {
            id: 'probinar_office_tour',
            link: 'JpKPEapmCLQ?si=sanG3DT4lyDVbs1A'
        },
        {
            id: 'probinar_launch',
            link: '1dnL27wC9hI?si=xF7hvF74Rw8O9bDL'
        },
        {
            id: 'premium_staking_club',
            link: 'rDbvvJgeKhs?si=0lDDzxWdCB2BxK_Z'
        },
        {
            id: '3D_Animated_tour',
            link: '2qTEPg-nFfA?si=aG_orcgi8QO4TSn-'
        },
        {
            id: 'TapyMax_celebration',
            link: 'D1lqXC1Y6_8?si=Oz7w1Dvgn9xslRnE'
        },
        {
            id: 'ultraPro_launches_GEBikes',
            link: 'dFmjC_0zO6U?si=8yTtsA8_EhD4lD91'
        },
        {
            id: 'celebrating_5M',
            link: 'F-l-o8sU-qc?si=i_PgB8sJF7wODYnf'
        },
        {
            id: 'play_Store_success',
            link: 'HcV6R4BKguk?si=RfkP-MAQlKskkUN8'
        }
    ]

    return (
        <div className="row pt-lg-3 align-items-center">
            <div className="col-md-6 position-relative">
                <FontAwesomeIcon
                    className="arrow_up cursor-pointer"
                    icon={faArrowUp}
                    onClick={Arrowup}
                />
                <FontAwesomeIcon
                    className="arrow_down cursor-pointer"
                    icon={faArrowDown}
                    onClick={Arrowdown}
                />
                <div
                    className="nav flex-column nav-pills me-3"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                >

                    {btn_tab.slice(startValue, endValue).map((value, index) => {
                        const globalIndex = startValue + index;
                        return (
                            <button
                                key={index}
                                className={`nav-link ${count === globalIndex ? "active" : ""}`}
                                id={`v-pills-${value.id}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#v-pills-${value.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`v-pills-${value.id}`}
                                onClick={() => HandleClick(globalIndex)}
                                aria-selected={count === globalIndex}
                            >
                                {value.btn}
                            </button>
                        );
                    })}
                </div>
            </div>

            <div className="col-md-6 pt-2">
                <div className="tab-content" id="v-pills-tabContent">

                    {tabContent.map((value, index) => (
                        <div
                            key={index}
                            className={`tab-pane fade show ${count === index ? "active" : ""}`}
                            id={`v-pills-${value.id}`}
                            role="tabpanel"
                            aria-labelledby={`v-pills-${value.id}-tab`}
                            tabIndex="0">

                            <div className="position-relative">
                                <span className="fw600 font_inter text_whites">{index + 1}/{tabContent.length}</span>
                                <iframe
                                title={value.id}
                                   src={
                                    count === index
                                      ? `https://youtube.com/embed/${value.link}?autoplay=1`
                                      : ""
                                  }
                                  className="event_video"
                                  allowFullScreen
                                   />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Events