import React, { useState, useRef } from 'react'
import founder from '../images/founder.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';


function AboutUsSection3() {

    const scrollRef = useRef(null); // To reference the scrollable container
    const [isDragging, setIsDragging] = useState(false); // Track dragging state
    const [startX, setStartX] = useState(0); // Store the initial click X position
    const [scrollLeft, setScrollLeft] = useState(0); // Store initial scroll position

    const handleMouseDown = (e) => {
        const scrollElement = scrollRef.current;
        setIsDragging(true);
        setStartX(e.pageX - scrollElement.offsetLeft); // Get initial click position
        setScrollLeft(scrollElement.scrollLeft); // Get current scroll position
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return; // Do nothing if not dragging
        e.preventDefault();
        const scrollElement = scrollRef.current;
        const x = e.pageX - scrollElement.offsetLeft; // Current mouse position
        const walk = (x - startX) * 2; // The multiplier controls scroll speed
        scrollElement.scrollLeft = scrollLeft - walk; // Update scroll position
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false); // Stop dragging
    };


    const handleRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 300, behavior: "smooth" })
        }
    }

    const handleLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -300, behavior: "smooth" })
        }
    }


    return (
        <div className="about_us_section_3">
            <div className="container">
                <div className="row align-items-center">
                    <h2>Nagarajan Narayanasamy</h2>
                    <div className="col-md-6 info">
                        <p className="fw600">Founder of <span>Ultrapro Blockchain</span></p>
                        <p>Ultrapro Blockchain, founded in 2021, is committed to decentralizing the internet through advanced blockchain technology and DApps. In 2023, we launched our official website, and by 2024, we incorporated our office in India, reinforcing our global expansion.</p>
                        <p>Guided by our founder, Mr. Nagarajan Narayanasamy, we continue to innovate and lead in the blockchain space with secure and scalable solutions.</p>
                        <p>Our esteemed founder, Mr.Nagarajan Narayanasamy, an accomplished MBA graduate from the prestigious Indian Institute of Management (IIM). He brings a wealth of expertise and strategic vision to the company. His leadership has been pivotal in positioning Ultrapro Blockchain at the forefront of the industry. With a profound passion for technology and innovation, our founder is dedicated to pushing the boundaries of what is possible in the blockchain.</p>
                    </div>
                    <div className="col-md-6 justify-content-center d-flex">
                        <img loading='lazy' src={founder} alt='founder-img' className="img-fluid" />
                    </div>
                </div>
                <div className="position-relative d-none d-md-block" >

                    <FontAwesomeIcon icon={faChevronLeft} className='left-arrow' onClick={handleLeft} />
                    <FontAwesomeIcon icon={faChevronRight} className='right-arrow' onClick={handleRight} />

                    <div ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave} className='timeline row'>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="active">2023 - Q1</label>
                            </div>
                            <div className="down_container">
                                <p>ULTRAPRO BLOCKCHAIN LAUNCHED</p>
                            </div>
                        </div>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="">Q1 TO Q4 - 2023</label>
                            </div>
                            <div className="down_container">
                                <p>DEVELOPMENT OF UPRO COIN,<br />
                                    ULTRAPROSCAN.IO,<br />
                                    UPROSWAP,<br />
                                    CANOPUS GTX WALLET,<br />
                                    UPRONFT,<br />
                                    UPROPAYMENTS
                                </p>
                            </div>
                        </div>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="">Q2 - 2024</label>
                            </div>
                            <div className="down_container">
                                <p>PROBINAR EDUCATIONAL PLATFORM LAUNCH <Link to='https://www.probinar.in/' target='_blank'>(www.probinar.in)</Link></p>
                            </div>
                        </div>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="">Q2 - 2024</label>
                            </div>
                            <div className="down_container">
                                <p>ULTRAPRO CRYPTO EXCHANGE DEVELOPMENT</p>
                            </div>
                        </div>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="">Q3 - 2024</label>
                            </div>
                            <div className="down_container">
                                <p>ULTRAPRO CRYPTO EXCHANGE LAUNCH <Link to='https://www.ultraproex.com/' target='_blank'>(www.ultraproex.com)</Link></p>
                            </div>
                        </div>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="">Q4 - 2024</label>
                            </div>
                            <div className="down_container">
                                <p>ULTRAPRO CRYPTO EXPO</p>
                            </div>
                        </div>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="">Q1 - 2025</label>
                            </div>
                            <div className="down_container">
                                <p>ULTRAPRO BAZZAR EXPANSION</p>
                            </div>
                        </div>
                        <div className="timeline_container col-4 col-lg-3 p-0">
                            <div className="top_container">
                                <label className="">Q2 - 2025</label>
                            </div>
                            <div className="down_container">
                                <p>METAVERSE & GAMING PLATFORM DEVELOPMENT</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default AboutUsSection3