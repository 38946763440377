import React, { useEffect, useState } from "react";
import "./ourTeam.css";
import CryptoJS from 'crypto-js'
import postRequest from "../../Layout/apifetch";
import { config } from "../../config";


function OurTeam() {

  const [teamList, setTeamList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      const url = config.team_url;
      const token = process.env.REACT_APP_TEAM_TOKEN;
      const payload = {};
      const securityKey = config.securityKey;
      try {
        const response = await postRequest(url, payload, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        if (response) {
          const encryptedData = response.payload;
          const decryptedData = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(securityKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
          const result = decryptedData.toString(CryptoJS.enc.Utf8);
          const parsedResult = JSON.parse(result)
          setTeamList(parsedResult.data.list);
          setLoading(false)
        }
      }
      catch(error){
        console.error(error)
      }
    }

    fetchData();
  }, [])

  return (
    <div>
      <div className="about_us_section_1">
        <div className="container">
          <h1>Our Visionary Team</h1>
          <p>
            Our collective of skilled professionals, each bringing unique
            expertise, is dedicated to creating a blockchain platform that
            transforms the way people engage with cryptocurrency. Meet the
            talented minds and passionate individuals working behind the scenes
            to make Ultrapro a leader in the industry!
          </p>
        </div>
      </div>
      <div className="team-section-2">
        <div className="container">
          <div className="row">

            {loading && <div className="loading">Loading...</div>}
            {teamList.map((data, index) => (
              <div className="col-6 col-md-3" key={index}>
                <div className="emp">
                  <div className="emp-img">
                    <img
                      src={data.teamsImage}
                      alt="employee"
                      className="img-fluid"
                    />
                  </div>
                  <div className="emp-content">
                    <h3>{data.teamsName}</h3>
                    <p>{data.teamsStack}</p>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
