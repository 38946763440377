import React, { useState, useEffect, useMemo } from 'react'
import placeholder from '../images/placeholder.png';


function AboutUsSection4() {
    const [activetab, setActiveTab] = useState(0);
    const menuTabs = useMemo(() => ['v-pills-upro-launch-tab', 'v-pills-upro-milestone-tab'],[]);
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveTab((pv) => (pv + 1) % menuTabs.length);
        }, 3000)
        return () => clearInterval(interval);

    }, [menuTabs.length])

    useEffect(() => {
        document.getElementById(menuTabs[activetab]).click();
    }, [activetab, menuTabs]);
    return (
        <div className="about_us_section_4">
            <div className="container">
                <div className="vertical_menu row align-items-center">
                    <div className="tab-content v_menu_content col-12 col-lg-6 text-center" id="v-pills-tabContent">
                        <div className="tab-pane v-tab-pane fade show active" id="v-pills-upro-launch" role="tabpanel" aria-labelledby="v-pills-upro-launch-tab">
                            <img loading='lazy' src={placeholder} alt='placeholder' className="img-fluid" />
                        </div>
                        <div className="tab-pane v-tab-pane fade" id="v-pills-upro-milestone" role="tabpanel" aria-labelledby="v-pills-upro-milestone-tab">
                            <img loading='lazy' src={placeholder} alt='placeholder' className="img-fluid" />
                        </div>
                    </div>

                    <div className="nav flex-column nav-pills col-12 col-lg-6" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <h2>Key Achievements</h2>
                        <div className="tab_btn mt-5">
                            <button className="nav-link v-nav-link active" id="v-pills-upro-launch-tab" data-bs-toggle="pill" data-bs-target="#v-pills-upro-launch" type="button" role="tab" aria-controls="v-pills-upro-launch" aria-selected="true"><h3>UPRO Launch</h3>
                                <p>We are excited to share the progress we’ve made over the past year. We have been committed to advancing the decentralization of the internet with our native coin, UPRO.</p>
                                <p>In this past year, we have distributed nearly 2 million UPROs through airdrops. Along with that, We have honored the UPRO ranging from 1 cent to 10 cents. This initiative was designed to foster community engagement and promote the adoption of UPRO within our ecosystem.</p>
                            </button>
                            <button className="nav-link v-nav-link my-2" id="v-pills-upro-milestone-tab" data-bs-toggle="pill" data-bs-target="#v-pills-upro-milestone" type="button" role="tab" aria-controls="v-pills-upro-milestone" aria-selected="false">
                                <h3>UPRO Milestones</h3>
                                <p>We are thrilled to announce that UPRO is officially launching in October 2024. This milestone marks a significant step forward in our mission to support and enhance decentralized networks. As we prepare for this exciting launch, we remain dedicated to providing innovative solutions and contributing to the future of blockchain technology.</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsSection4