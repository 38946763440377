import React from "react";
import TAPYMAAX from "../images/tapymaax.png";
import ultrapro from "../images/ultrapro.png";
import canopus from "../images/canopus.png";
import probinar from "../images/probinar.png";
import Client1 from "../images/client-1.png";
import Client2 from "../images/client-2.png";
import Client3 from "../images/client-3.png";
import Client4 from "../images/client-4.png";
import Client5 from "../images/client-5.png";
import Client6 from "../images/client-6.png";
import Client7 from "../images/client-7.png";
import Client8 from "../images/client-8.png";
import Client9 from "../images/client-9.png";
import Client10 from "../images/client-10.png";

function HomeSection9() {
  const v_marquee = [
    {
      logo: TAPYMAAX,
      name: "TAPYMAAX",
    },
    {
      logo: ultrapro,
      name: "ULTRAPRO",
    },
    {
      logo: canopus,
      name: "CANOPUS",
    },
    {
      logo: probinar,
      name: "PROBINAR",
    },
    {
      logo: Client1,
      name: "Shopee Today",
    },
    {
      logo: Client2,
      name: "Ultrapro Blockchain",
    },
    {
      logo: Client3,
      name: "UPRO NFT",
    },
    {
      logo: Client4,
      name: "eena Swap",
    },
    {
      logo: Client5,
      name: "Ucoin Pay",
    },
    {
      logo: Client6,
      name: "Green Energy",
    },
    {
      logo: Client7,
      name: "Pogo Shopy",
    },
    {
      logo: Client8,
      name: "Tree Donate",
    },
    {
      logo: Client9,
      name: "Green Energy",
    },
    {
      logo: Client10,
      name: "PRO20",
    },
  ];
  return (
    <div className="section_9">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <h2 className="ms-5">Our Flagship Products</h2>
          </div>
          <div className="col-md-6 d-flex gap-4 align-items-center justify-content-center">
            <div className="marquee">
              <div className="marquee_container">
                {v_marquee.map((item, index) => (
                  <div
                    key={index}
                    className={`products d-flex flex-column align-items-center justify-content-center p-4 gap-3 ${index % 2 === 0 ? "my-3" : ""
                      }`}
                  >
                    <img
                      src={item.logo}
                      alt={item.name}
                      width={`${index === 1 ? "80" : ""}`}
                    />
                    <p>{item.name}</p>
                  </div>
                ))}
              
              </div>
              <div className="marquee_container">
                {v_marquee.map((item, index) => (
                  <div
                    key={index}
                    className={`products d-flex flex-column align-items-center justify-content-center p-4 gap-3 ${index % 2 === 0 ? "my-3" : ""
                      }`}
                  >
                    <img
                      src={item.logo}
                      alt={item.name}
                      width={`${index === 1 ? "80" : ""}`}
                    />
                    <p>{item.name}</p>
                  </div>
                ))}
              
              </div>

            </div>
            <div className="marquee1">
            <div className="marquee_container">
                {v_marquee.map((item, index) => (
                  <div
                    key={index}
                    className={`products d-flex flex-column align-items-center justify-content-center p-4 gap-3 ${index % 2 === 0 ? "my-3" : ""
                      }`}
                  >
                    <img
                      src={item.logo}
                      alt={item.name}
                      width={`${index === 1 ? "80" : ""}`}
                    />
                    <p>{item.name}</p>
                  </div>
                ))}
              
              </div>
              <div className="marquee_container">
                {v_marquee.map((item, index) => (
                  <div
                    key={index}
                    className={`products d-flex flex-column align-items-center justify-content-center p-4 gap-3 ${index % 2 === 0 ? "my-3" : ""
                      }`}
                  >
                    <img
                      src={item.logo}
                      alt={item.name}
                      width={`${index === 1 ? "80" : ""}`}
                    />
                    <p>{item.name}</p>
                  </div>
                ))}
              
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSection9;
