import React from 'react'
import { faFaceFrown } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PageNotFound() {
  return (
    <div className='p-5 text-center text-white'>
        <h1><FontAwesomeIcon icon={faFaceFrown}/></h1>
        <h2>OOPS ! - 404</h2>
        <p>The page you are looking for is not available.</p>
        
    </div>
  )
}

export default PageNotFound