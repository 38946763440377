import axios from "axios";

const postRequest = async (url, payload, headers) => {
    try {
        const response = await axios.post(url, payload, { headers });
        return response.data;
    }
    catch (error) {
        console.error(error)
    }
}

export default postRequest