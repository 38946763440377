import React, { useContext, useEffect, useRef, useState } from 'react'
import './terms.css'
import { Link } from 'react-router-dom'
import logo from '../home/images/logo.png';
import { Helmet } from 'react-helmet-async';
import { ThemeContext } from '../../Layout/ThemeContext';

function TermsUSe() {

    const ansRef = useRef([]);
    const [currentIndex, setCurrentIndex] = useState(null)

    const handleScroll = (index) => {
        setCurrentIndex(index);
        if (ansRef.current[index]) {
            const offset = 80; // navbar height
            const elementPosition = ansRef.current[index].getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({ top: offsetPosition });
        }
    }

    const ques = ["1. Acceptance of Terms",
        "2. Eligibility",
        "3. User Responsibilities",
        "4. Prohibited Activities",
        "5. Intellectual Property",
        "6. Disclaimer of Warranties",
        "7. Limitation of Liability",
        "8. Indemnification",
        "9. Third-Party Links",
        "10. No Advice",
        "11. Modification of Terms",
        "13. Independent Contractor",
        "14. Severability",
        "15. Entire Agreement",
        "16. Disclaimer",
        "17. Contact Us"
    ]

    useEffect(() => {
        const currentRefs = ansRef.current;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = ansRef.current.indexOf(entry.target);
                        setCurrentIndex(index);
                    }
                });
            },
            {
                root: null, // relative to the viewport
                rootMargin: '0px',
                threshold: 1, // trigger when 100% of the section is visible
            }
        );

        currentRefs.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        // Cleanup the observer when component unmounts
        return () => {
            currentRefs.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [])

    const { theme } = useContext(ThemeContext)

    return (
        <div>
            <Helmet>
                <title>Terms of Use – Ultrapro Blockchain Compliance Guide (or) Ultrapro Blockchain Platform: User Terms and Policies</title>
                <meta name='description' content="Ultrapro Blockchain's Terms of Use provide clarity on user conduct, data security, and compliance to promote a safe trading environment"></meta>
                <meta name='keywords' content='Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer1 blockchain, EVM Compatible, PoA Consensus Algorithm, What is the term for a blockchain?,Terms of use blockchain technology,Blockchain technology,Blockchain terms,
Blockchain terms list,What is blockchain in technical terms?,What is blockchain in simple terms?, What is blockchain technology and how does it work'></meta>
            </Helmet>
            <div className="terms_section_1 ">
                <div className="container d-flex flex-column align-items-center justify-content-center">
                    <p>Last Updated : October 2024</p>
                    <h1>ULTRAPRO BLOCKCHAIN<br /> TERMS AND USE</h1>
                    <button>Download</button>
                </div>

            </div>

            <div className="terms_section_2">
                <div className="container">
                    <label>Terms & Use</label>
                </div>
            </div>

            <div className="terms_section_3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 logo_container">
                            <img src={logo} alt='logo' />
                            <p>ULTRAPRO</p>
                            <Link to={'/'} className='text-decoration-none'>www.ultrapro.info</Link>
                            <div id="list-terms" className="list-group mt-5">
                                {ques.map((ques, index) => (
                                    <button key={index} className={`list-group-item list-group-item-action ${currentIndex === index ? theme === 'dark' ? 'fw-bold text-white' : 'fw-bold text-black' : ''}`} onClick={() => handleScroll(index)}>{ques}</button>
                                ))}

                            </div>

                        </div>
                        <div className="col-md-8 terms_content">
                            <p>Thank you for choosing to visit Ultrapro Blockchain (the “Website”). The following terms and conditions of use (the “Terms”) apply to all visitors of the Website (“you,” “your,” or “Website User”) and are entered into by and between you and Ultrapro Blockchain (“we,” “us,” or “Ultrapro”).</p>

                            <p>Please read these Terms carefully, as they govern your access to and use of the Website, along with any services, functions, features, or content available on the Website from time to time, including but not limited to the Ultrapro Ecosystem, staking opportunities, and educational resources (“Services”).</p>

                            <p>You understand and agree that the Website serves as an interface for a community of users and developers interested in the Ultrapro Blockchain infrastructure and its innovations. Ultrapro Blockchain is a Layer 1 network designed to provide a secure and efficient foundation for various blockchain applications, utilizing its native token, UPRO. Decisions regarding the platform are made collaboratively by the members of the community, rather than by a single entity.</p>

                            <p>We may amend these Terms at any time at our sole discretion. The “Last Updated” section at the beginning of these Terms will indicate the most recent date of such amendments, which shall take effect immediately unless explicitly stated otherwise. It is your responsibility to check these Terms regularly and stay informed of any changes as long as you continue accessing or using the Website or any Services.</p>

                            <p>If you disagree with these Terms or any subsequent amendments, changes, or updates, you may not use the Website or any of the Services; your only recourse in the case of disagreement is to stop accessing the Website. In the event of any inconsistency between these Terms and any other pages or policies on the Website, these Terms shall prevail.</p>

                            <div className="list_item">

                                <>
                                    <h4 ref={(el) => (ansRef.current[0] = el)} id="list-item-1">1. Acceptance of Terms</h4>
                                    <p>By using Ultrapro Blockchain’s website and services, you agree to these Terms of Use. If you do not agree, you must not use our services.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[1] = el)} id="list-item-2">2. Eligibility</h4>
                                    <p>You must be at least 18 years old to use our services. By using our platform, you represent and warrant that you meet this eligibility requirement. If you are using the services on behalf of an organization, you represent that you have the authority to bind that organization to these Terms of Use.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[2] = el)} id="list-item-3">3. User Responsibilities</h4>
                                    <ul className='user-resp'>
                                        <li><strong>Account Security:</strong> Users are responsible for maintaining the confidentiality of their account and password and for all activities that occur under their account. Notify us immediately of any unauthorized use of your account or any other breach of security.</li>
                                        <li><strong>Compliance:</strong> Users agree to comply with all applicable laws and regulations when using Ultrapro Blockchain’s services. This includes adhering to local laws governing cryptocurrency transactions and digital assets.</li>
                                    </ul>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[3] = el)} id="list-item-4">4. Prohibited Activities</h4>
                                    <p>You may not use our services for any illegal, fraudulent, or unauthorized purpose, including but not limited to:</p>
                                    <ul>
                                        <li>Money laundering, financing of terrorism, or other illicit activities.</li>
                                        <li>Attempting to gain unauthorized access to our systems or networks.</li>
                                        <li>Transmitting any viruses, malware, or harmful code.</li>
                                        <li>Engaging in any form of automated access or scraping of our website.</li>
                                    </ul>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[4] = el)} id="list-item-5">5. Intellectual Property</h4>
                                    <p>All content on the Ultrapro Blockchain website, including logos, text, graphics, and software, is the intellectual property of Ultrapro Blockchain or its licensors. Unauthorized use of this content is strictly prohibited. You may not reproduce, distribute, or modify any materials without prior written permission.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[5] = el)} id="list-item-6">6. Disclaimer of Warranties</h4>
                                    <p>Ultrapro Blockchain provides services on an "as-is" basis without any warranties, express or implied. We do not guarantee that our services will be uninterrupted, error-free, or secure. You acknowledge that you are using the services at your own risk.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[6] = el)} id="list-item-7">7. Limitation of Liability</h4>
                                    <p>To the fullest extent permitted by law, Ultrapro Blockchain, its affiliates, and their employees will not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the platform, including but not limited to any loss of funds, data, or profits. In no event shall our total liability to you exceed the amount paid by you for our services.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[7] = el)} id="list-item-8">8. Indemnification</h4>
                                    <p>You agree to indemnify, defend, and hold harmless Ultrapro Blockchain and its affiliates from any claims, damages, losses, liabilities, costs, or expenses (including reasonable attorney’s fees) arising out of your use of the services, your violation of these Terms of Use, or your infringement of any third-party rights.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[8] = el)} id="list-item-9">9. Third-Party Links</h4>
                                    <p>Our website may contain links to third-party websites. Ultrapro Blockchain does not control or endorse these sites and is not responsible for their content, privacy practices, or any damages incurred as a result of your use of such sites. We recommend reviewing the terms and privacy policies of any third-party websites you visit.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[9] = el)} id="list-item-10">10. No Advice</h4>
                                    <p>We do not provide any investment advice, trading strategies, or recommendations regarding the purchase, sale, or holding of any cryptocurrencies or digital assets. All users are encouraged to conduct their own research and consult with qualified financial advisors before making any investment decisions.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[10] = el)} id="list-item-11">11. Modification of Terms</h4>
                                    <p>We reserve the right to modify or update these Terms of Use at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services following any changes signifies acceptance of the revised terms. We encourage you to review these terms periodically.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[11] = el)} id="list-item-12">12. Termination of Service</h4>
                                    <p>Ultrapro Blockchain reserves the right to suspend or terminate your access to our services without notice if you violate these Terms of Use or if we believe that your conduct poses a risk to the integrity or security of our services.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[12] = el)} id="list-item-13">13. Independent Contractor</h4>
                                    <p>Each party to these Terms operates as an independent contractor. Nothing contained in these Terms, nor your access to or use of the Website or Services, shall create any association, partnership, or joint venture between the parties. These Terms do not imply any intention to establish such a relationship.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[13] = el)} id="list-item-14">14. Severability</h4>
                                    <p>If any provision of these Terms of Use is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[14] = el)} id="list-item-15">15. Entire Agreement</h4>
                                    <p>These Terms of Use, along with our Privacy Policy, constitute the entire agreement between you and Ultrapro Blockchain concerning your use of our services and supersede any prior agreements or understandings.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[15] = el)} id="list-item-16">16. Disclaimer</h4>
                                    <p>We provide the Website and any Services on an “as is” and “as available” basis. While we strive to ensure a seamless user experience, the Website and Services may be subject to limitations, delays, or other issues inherent to internet usage. We do not guarantee that the Website or Services will be continuously available, error-free, or free from interruptions at all times.</p>
                                </>

                                <>
                                    <h4 ref={(el) => (ansRef.current[16] = el)} id="list-item-17">17. Contact Us</h4>
                                    <p>For questions regarding these Terms of Use, please contact us at <Link className='mail' to='mailto: contact@ultrapro.info'>contact@ultrapro.info</Link></p>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsUSe