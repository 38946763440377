import React from "react";
import vector from "../images/Vector.png";
import la_token from "../images/LA_Token.png";
import koinpark from "../images/Konipark.png";
import lowgasfee from "../images/lowgasfee.png";
import d_system from "../images/d-system.png";
import playstore from "../images/playstore.png";
import applestore from "../images/apple-store.png";
import icon_group from "../images/icon_group.png";
import coinmarket from "../images/coinmarketcap.png";
import coingecko from "../images/coingecko.png";
import { Link } from "react-router-dom";

function HomeSection6() {
  const handleDivClick = (index) => {
    switch (index) {
      case 1:
        window.open("https://www.ultraproex.com/", "_blank");
        break;
      case 2:
        window.open("https://latoken.com/exchange/UPRO_USDT", "_blank");
        break;
      case 3:
        window.open("https://www.koinpark.com/trade/UPRO-INR", "_blank");
        break;
      case 4:
        window.open("https://coinmarketcap.com/currencies/ultrapro/", "_blank");
        break;
      case 5:
        window.open("https://www.coingecko.com/en/coins/ultrapro", "_blank");
        break;
      default:
        console.log("This div does not have a defined URL.");
    }
  };

  return (
    <div className="section_6">
      <div className="container">
        <h2>
          CRYPTOCURRENCY <span>EXCHANGE</span>
        </h2>
        <div className="row align-items-center">
          <div className="col-md-8 exchange p-4">
            <label>UPRO Cryptocurrency</label>
            <div className="my-4">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Coin Listing Exchange
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Market Cap
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row gap-3 align-items-center coin-exchange">
                  <div
                    onClick={() => handleDivClick(1)}
                    className="col-5 col-md-3 exchange_menu_card highlight d-flex flex-column align-items-center justify-content-center gap-2"
                  >
                    <img loading="lazy" src={vector} alt="ultraexpo-logo" className="ultraexpo-logo" />
                    <label>ULTRAPRO</label>
                  </div>
                  <div
                    onClick={() => handleDivClick(2)}
                    className="col-5 col-md-3 exchange_menu_card d-flex flex-column align-items-center justify-content-center gap-2"
                  >
                    <img loading="lazy" src={la_token} alt="laToken-logo" />
                    <label>LAToken</label>
                  </div>
                  <div
                    onClick={() => handleDivClick(3)}
                    className="col-5 col-md-3 exchange_menu_card d-flex flex-column align-items-center justify-content-center gap-2"
                  >
                    <img loading="lazy" src={koinpark} alt="koinpark-logo" width="52" height="52" />
                    <label>Koinpark</label>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="row gap-3 align-items-center justify-content-center justify-content-md-start">
                  <div onClick={() => handleDivClick(4)} className="col-5 col-md-3 exchange_menu_card d-flex flex-column align-items-center justify-content-center gap-2">
                    <img loading="lazy" src={coinmarket} alt="coinmarket-logo" width="75" height="52" />
                    <label>Coinmarketcap</label>
                  </div>
                  <div onClick={() => handleDivClick(5)} className="col-5 col-md-3 exchange_menu_card d-flex flex-column align-items-center justify-content-center gap-2">
                    <img loading="lazy" src={coingecko} alt="coingecko-logo" width="52" height="52" />
                    <label>Coingecko</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="exchange_card py-2 px-4 lowgasfee">
              <img loading="lazy" src={lowgasfee} alt="lowgasfee" className="w-100" />
              <p className="text-center fw500">
                Total Supply - 1.9 Billion UPRO
              </p>
            </div>
          </div>
        </div>
        <div className="row my-4 d-none d-md-flex">
          <div className="col-md-0 col-lg-4">
            <img loading="lazy" src={d_system} alt="d-System" className="img-fluid w-100 d-none d-lg-block" />
          </div>
          <div className="col-md-6 col-lg-4 mt-4 mt-md-0">
            <div className="exchange_card list-2 h-100 d-flex flex-column justify-content-center">
              <p className="text-center">
                Trade
                <br /> Anywhere
              </p>
              <div className="d-flex align-items-center justify-content-center gap-2 mt-md-4">
                <Link to="https://play.google.com/store/apps/details?id=com.application.ultrapro&pcampaignid=web_share" target="_blank">
                  <img loading="lazy" src={playstore} alt="playstore" className="app_icon" />
                </Link>
                <Link to="#" className=" apple_store">
                  <img loading="lazy" src={applestore} alt="applestore" className="app_icon" />
                  <label className="apple_store_label">Coming Soon</label>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mt-4 mt-md-0">
            <div className="exchange_card d-flex flex-column justify-content-center align-items-center gap-3 icon_group h-100">
              <img loading="lazy" src={icon_group} alt="icon-group" className="group_icon" />
              <p className="fw500 text-center ">
                Stake <span className="text-orange">UPRO</span> to
                <br /> earn good rewards
              </p>
              <Link className="rounded " to="https://www.ultraproex.com/" target="_blank">
                Get started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSection6;
