import React, { useState } from "react";
import "./blog.css";
import Announcement from "./components/announcement";
import PressRelease from "./components/pressRelease";
import MarketUpdates from "./components/marketUpdates";
import Earn from "./components/earn";
import CryptoCurrency from "./components/cryptoCurrency";
import { Helmet } from "react-helmet-async";

function Blog() {
  const [activeTab, setActiveTab] = useState("Announcement");

  return (
    <div>
      <Helmet>
        <title>Explore the Future Insights into Blockchain and Finance</title>
        <meta
          name="description"
          content="Explore the intersection of blockchain technology and finance through our insightful blog. Stay updated on the latest trends, advancements and opportunities"
        ></meta>
        <meta
          name="keywords"
          content="Blockchain finance, Fintech insights, Cryptocurrency updates, Financial technology trends, Blockchain innovations, Investment insights"
        ></meta>
      </Helmet>
      <div className="blog_section_1">
        <div className="container">
          <h1>Blog</h1>
        </div>
      </div>
      <div className="blog_section_2">
        <div className="container">
          <div className="d-flex justify-content-center">
            <ul
              className="nav nav-pills mb-3 justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "Announcement" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Announcement")}
                  type="button"
                >
                  Announcements
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "PressRelease" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("PressRelease")}
                  type="button"
                >
                  Press Release
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "MarketUpdates" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("MarketUpdates")}
                  type="button"
                >
                  Market Updates
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === "Earn" ? "active" : ""}`}
                  onClick={() => setActiveTab("Earn")}
                  type="button"
                >
                  Earn
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "CryptoCurrency" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("CryptoCurrency")}
                  type="button"
                >
                  Cryptocurrencies
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content mt-5">
            {activeTab === "Announcement" && <Announcement />}

            {activeTab === "PressRelease" && <PressRelease />}

            {activeTab === "MarketUpdates" && <MarketUpdates />}

            {activeTab === "Earn" && <Earn />}

            {activeTab === "CryptoCurrency" && <CryptoCurrency />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
