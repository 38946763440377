import React from 'react'
// import { Link } from 'react-router-dom'

function AboutUsSection1() {
  return (
    <div className="about_us_section_1">
    <h1>Discover Our Vision,<br/> Shape the Future</h1>
    <p>Ultrapro Blockchain is a leader in pioneering the decentralization of the internet through state-of-the-art blockchain technology and decentralized applications (DApps).</p>
    <div className="d-flex align-items-center justify-content-center about-us-action my-5">
        {/* <Link to="/">ABOUT US</Link> */}
        <button>ABOUT US</button>
    </div>
</div>
  )
}

export default AboutUsSection1