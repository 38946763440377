import React, { useEffect, useState } from 'react'
import './style.css'
import HomeSection1 from './components/homeSection1';
import HomeSection2 from './components/homeSection2';
import HomeSection3 from './components/homeSection3';
import HomeSection4 from './components/homeSection4';
import HomeSection5 from './components/homeSection5';
import HomeSection6 from './components/homeSection6';
import HomeSection7 from './components/homeSection7';
// import HomeSection8 from './components/homeSection8';
import HomeSection9 from './components/homeSection9';
import HomeSection10 from './components/homeSection10';
import HomeSection11 from './components/homeSection11';
import ModalPopup from './components/modal';
import { Helmet } from 'react-helmet-async';


function Home() {
  const [showModal, setShowModal] = useState(false);
  useEffect(()=>{
    const timer  = setTimeout(()=>{
      setShowModal(true)
    },3000);

    return()=> clearTimeout(timer);
  },[]);
  return (
    <div>
      <Helmet>
        <title>Ultrapro Blockchain | Powering the Next digital Era</title>
        <meta name='description' content='Ultrapro Blockchain opens doors to a decentralized financial future, offering unparalleled security, transparency, and the transformative power of UPRO coin'></meta>
        <meta name='keywords' content='Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer 1 blockchain, EVM Compatible, PoA Consensus Algorithm'></meta>
      </Helmet>
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />
      <HomeSection5 />
      <HomeSection6 />
      <HomeSection7 />
      {/* <HomeSection8 /> */}
      <HomeSection9 />
      <HomeSection10 />
      <HomeSection11 />
      {showModal && <ModalPopup setShowModal={setShowModal} showModal={showModal}/>}
      
    </div>
  )
}

export default Home