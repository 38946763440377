import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainLayOut from "../../../Layout/MainLayOut";

const FAQ_DATA = [
  {
    question: "Is my personal information secure when contacting you?",
    answer:
      "Yes, we take your privacy seriously. All communications with our support team are encrypted and handled securely to protect your personal information.",
  },
  {
    question: "How long does it take to get a response to my inquiry?",
    answer:
      "We strive to respond to all inquiries promptly. Most queries are addressed within 24 hours, but response times may vary depending on the complexity of the issue.",
  },
  {
    question:
      "What should I do if I encounter technical issues on your platform?",
    answer:
      "If you experience technical issues, please contact our support team immediately. Provide as much detail as possible about the issue to help us resolve it quickly.",
  },
  {
    question: "How can I contact Ultrapro Exchange for support?",
    answer:
      "You can reach us 24/7 via email, phone, or social media. Visit our Contact Us page for detailed contact information.",
  },
  {
    question:
      "Can I contact customer support outside of regular business hours?",
    answer:
      "Yes, our customer support team is available 24/7 to assist you with any issues or questions you may have.",
  },
];

function FAQ() {
  return (
    <MainLayOut styles="contact-bg-img">
      <Container>
        <Row className="justify-content-center align-items-center text_whites">
          <Col md={11}>
            <div className="d-flex flex-column align-items-center Frequently">
              <h5>Question & Answer</h5>
              <h4 data-aos="fade-up" data-aos-duration="1000">
                Frequently Asked Questions (FAQ)
              </h4>
              <p className="text-white">
                Browse our FAQs below. If you cannot find the answer you're
                looking for, please contact us.
              </p>
            </div>
            <div className="accordion my-2" id="accordionPanelsStayOpenExample">
              {FAQ_DATA.map((item, index) => (
                <div className="accordion-item mt-3 mx-md-5" key={index}>
                  <h2
                    className="accordion-header"
                    id={`panelsStayOpen-heading${index}`}
                  >
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-collapse${index}`}
                      aria-expanded={index === 0}
                      aria-controls={`panelsStayOpen-collapse${index}`}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`panelsStayOpen-collapse${index}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`panelsStayOpen-heading${index}`}
                  >
                    <div className="accordion-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayOut>
  );
}

export default FAQ;
