import React from "react";
import Accodin from "./components/Accodin";
import "./contact.css";
import Layouts from "../../Layout/Childrens";
import { Container, Row, Col } from "react-bootstrap";
import MainLayOut from "../../Layout/MainLayOut";
import { Helmet } from "react-helmet-async";
import ContactForm from "./components/contact_form";
import HomeSection11 from "../home/components/homeSection11";
import { Link } from "react-router-dom";
function contact() {
  return (
    <>
      <Helmet>
        <title>TGet in Touch with Ultrapro Blockchain</title>
        <meta
          name="description"
          content="Get personalized assistance from Ultrapro Blockchain’s expert team. Contact us for inquiries, support, or collaborations in blockchain tech"
        ></meta>
        <meta
          name="keywords"
          content="Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer1 blockchain, EVM Compatible, PoA Consensus Algorithm, Contact us, contact number for blockchain,Is blockchain support 24/7?,blockchain contact information,How do I find my blockchain address?"
        ></meta>
      </Helmet>
      <section className="Contact-banner-main">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={11}>
              <div className=" py-5 text_whites">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="contact-banner">
                      <h3 className="font_inter">We're here to help!</h3>
                      <h5>
                        Whether you have questions, need support, or want to
                        learn more about Ultrapro Blockchain, feel free to reach
                        out to us.
                      </h5>
                    </div>
                    <div className="sociel_icons">
                      <h5>General Inquiries</h5>
                      <p>
                        Drop us a message, and we'll get back to you as soon as
                        possible.{" "}
                      </p>
                      <h5>Reach out to us at:</h5>
                      <div className="d-lg-flex gap-4">
                        <div className="d-flex gap-2 ">
                          <Link data-aos="zoom-in-down" data-aos-duration="1000" to="mailto: info@ultrapro.info" className="mail">
                            info@ultrapro.info
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <Layouts styles="form_1">
                      <ContactForm />
                    </Layouts>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <HomeSection11 />
      </section>
      <MainLayOut styles="accordions_page py-2 py-sm-5">
        <Accodin />
      </MainLayOut>
    </>
  );
}

export default contact;
