import { useEffect, useRef, useState } from "react";

const tiers = [
  {
    title: "Advanced Standards",
    subtitle: "Tier 1",
    description:
      "Trading Volume & User Base: Basic level trading volume and user base, suited for smaller exchanges or new market entrants.",
    points: [
      "KYC Compliance and Public Visibility: Exchange owners must complete KYC verification and be publicly visible to ensure the security of public funds.",
      "Ethical Coin Listing Practices: Exchanges must prioritize listing coins and tokens that meet basic criteria and benefit the user community, rather than those driven by financial gain.",
      "Local Office Establishment: Exchanges with a growing user base must open local offices in regions where their presence is increasing to provide better support.",
    ],
  },
  {
    title: "Intermediate Standards",
    subtitle: "Tier 2",
    description:
      "Tier 2 exchanges have significantly lower trading volume and user base than Tier 1, reflecting their dominant market position.",
    points: [
      "KYC Compliance and Public Visibility: Exchange owners must complete KYC verification and be publicly visible to ensure the security of public funds.",
      "Ethical Coin Listing Practices: Exchanges must prioritize listing coins and tokens that meet basic criteria and benefit the user community, rather than those driven by financial gain.",
      "Local Office Establishment: Exchanges with a growing user base must open local offices in regions where their presence is increasing to provide better support.",
    ],
  },
  {
    title: "Basic Standards",
    subtitle: "Tier 3",
    description:
      "Tier 3 exchanges have significantly lower trading volume and user base than Tier 2, reflecting their dominant market position.",
    points: [
      "KYC Compliance and Public Visibility: Exchange owners must complete KYC verification and be publicly visible to ensure the security of public funds.",
      "Ethical Coin Listing Practices: Exchanges must prioritize listing coins and tokens that meet basic criteria and benefit the user community, rather than those driven by financial gain.",
      "Local Office Establishment: Exchanges with a growing user base must open local offices in regions where their presence is increasing to provide better support.",
    ],
  },
];

const SvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
  </svg>
);

export default function TopCrypto() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isHovered === null) {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % tiers.length);
      }, 1500);
    }

    return () => clearInterval(intervalRef.current);
  }, [isHovered]);

  const handleMouseEnter = (index) => {
    setIsHovered(index);
    setCurrentIndex(index);
    clearInterval(intervalRef.current);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % tiers.length);
    }, 1500);
  };

  const divStyle = (index) => ({
    background:
      index === currentIndex
        ? "linear-gradient(180deg, #1f1f1f 30%, rgba(255,131,0,1) 100%)"
        : "",
    transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
  });

  const divHeader = (index) => ({
    top: isHovered === index || index === currentIndex ? "auto" : "",
    transform: isHovered === index || index === currentIndex ? "none" : "",
  });

  const divBody = (index) => ({
    maxHeight: isHovered === index || currentIndex === index ? "1000px" : "",
    overflow: isHovered === index || currentIndex === index ? "hidden" : "",
    opacity: isHovered === index || currentIndex === index ? 1 : 0,
    transition: "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
  });

  const getCardClass = (index) => {
    return `tie_bx1 ${
      isHovered === index || currentIndex === index ? "isActive" : ""
    }`;
  };

  return (
    <section className="scro_sc1">
      <section className="row justify-content-center align-items-center secro2">
        {tiers.map((tier, index) => (
          <div className="col-md-4" key={index}>
            <div
              className={getCardClass(index)}
              style={divStyle(index)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="tie_hov" style={divHeader(index)}>
                <h6 className="text_whites inter fw400 text-center pt-3">
                  {tier.title}
                </h6>
                <h4 className="text-uppercase inter fw600 text-center">
                  {tier.subtitle}
                </h4>
              </div>

              <div className="tie_hid" style={divBody(index)}>
                <ul className="tie_lis1">
                  {tier.points.map((point, pointIndex) => (
                    <li key={pointIndex}>
                      <SvgIcon />
                      {point}
                    </li>
                  ))}
                </ul>
                <p>{tier.description}</p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
}
