import React, { useState } from "react";
import ReactPlayer from "react-player";

function VideoPlayer({ videoSrc, thumbnail }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayPause = () => setIsPlaying(!isPlaying);

  return (
    <div
      className="video_player"
      onClick={handlePlayPause}
      
      style={{
        boxShadow: "inset 10px 10px 1px #1f1f1f",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <ReactPlayer
        url={videoSrc}
        light={thumbnail}
        playing={isPlaying}
        controls
        width="100%"
        height="100%" 
      />
    </div>
  );
}

export default VideoPlayer;
