import React from "react";
import expo1 from "./images/expo-1.png";
import expo2 from "./images/expo-2.png";
import expo3 from "./images/expo-3.png";
import expo4 from "./images/expo-4.png";
import logo7 from "./images/logo-7.png";
import logo8 from "./images/elogo-8.png";
import logo9 from "./images/logo-9.png";
import logo10 from "./images/logo-10.png";
import Images from "../Images";
import { Link } from "react-router-dom";

const objDatas = [
  { src: expo2, alt: "Expo 2" },
  { src: expo1, alt: "Expo 1" },
  { src: expo3, alt: "Expo 3" },
  { src: expo4, alt: "Expo 4" },
  { src: logo7, alt: "Logo 7" },
  { src: logo8, alt: "Logo 8" },
  { src: logo9, alt: "Logo 9" },
  { src: logo10, alt: "Logo 10" },
];

function Objdata() {
  return (
    <ul className="d-flex flex-wrap justify-content-between gap-3 egolies py-3 icon_socil">
      {objDatas.map((item, index) => (
        <li key={index}>
          <Link to="#">
            <Images link={item.src} alt={item.alt} />
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default Objdata;
