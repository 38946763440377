import React from 'react'
import globe from '../images/globe.png';
import { Link } from 'react-router-dom';


function HomeSection7() {
  return (
    <div className="section_7">
      <div className="container">
        <div className='pro_bk1'>
          <div className="row">
            <div className="col-lg-6 d-apps-content my-4">
              <h3 className="mb-3">Pro network</h3>
              <p className="my-4">Pro Network is designed for UltraPro MainNet, offering limitless scalability, minimal resource consumption, top-tier security, and customizable features. It empowers advanced blockchain applications with robust infrastructure, ensuring efficient operations and enhanced user experiences within the UltraPro ecosystem.</p>
              <Link className="rounded my-4" to="https://eenaswap.finance/" target='_blank'>Explore Uproswap Now</Link>
            </div>
            <div className="col-lg-6 d-none d-lg-block px-3">
              <div className='d-flex justify-content-end'>
                <img loading='lazy' src={globe} alt='globe' className="globe-img img-fluid" />
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default HomeSection7