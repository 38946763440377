import React from "react";
import Images from "./components/Images";
import "./style.css";
// import service from "./images/service-bg-img.png";
// import service3 from "./images/service-icon-1.png";
// import service2 from "./images/service-2.png";
// import service1 from "./images/service-3.png";
// import developer1 from "./images/developer-img-1.jpg";
// import developer2 from "./images/developer-img-2.jpg";
// import developer3 from "./images/developer-img-3.jpg";
// import filterimg1 from "./images/filter-png-1.png";
// import filterimg2 from "./images/filter-png-2.png";
// import filterimg3 from "./images/filter-png-3.png";
// import ultraproindoor1 from "./images/ultrapro-indoor2.jpg";
// import ultraproevent from "./images/ultrapro-event.jpg";
// import ultraproframe from "./images/ultrapro-frame.jpg";
// import ultraproindoor2 from "./images/ultra-indoor.jpg";
// import ultraprovisiter from "./images/visiter-img.jpg";
// import developer4 from "./images/develpers-img-4.jpg";

import weddin from "./images/rounded.png";
import { Helmet } from "react-helmet-async";
// import Swiperr from "./components/Swiperr";
// import { ThemeContext } from "../../Layout/ThemeContext";
import VideoPlayer from "./components/VideoPlayer";
import lineimg from "./images/bg-img-line.png";
import Url from "./images/App-promo.mp4";

import Sociel from "./components/Sociel";
import { Link } from "react-router-dom";
import Events from "./components/events";
function EnhancedEnterprises() {
  // const { theme } = useContext(ThemeContext);
  
  return (
    <>
      <Helmet>
        <title>Enterprise Innovation Hub</title>
        <meta
          name="description"
          content="Empowering digital transformation, Enhanced Enterprises utilizes Ultrapro Blockchain to make web technology accessible and secure"
        ></meta>
        <meta
          name="keywords"
          content="Ultrapro Blockchain, blockchain technology companies, use of blockchain technology, blockchain in cryptocurrency, Where is blockchain used?, What is Blockchain?, Layer1 blockchain, EVM Compatible, PoA Consensus Algorithm, Ultrapro Wallet, Creative enterprise hub,enterprise innovation meaning,enterprise blockchain solutions,What is enterprise blockchain?,what is enterprise innovation?,creative enterprise hub,What is an enterprise hub?,How blockchain enhances security?,Enterprise blockchain solutions"
        ></meta>
      </Helmet>
      <div className="about_us_section_1">
        <h1>Enterprise Innovation Hub</h1>
        <p>
          {" "}
          Enhanced Enterprises harnesses advanced products like Ultrapro
          Blockchain, Ultrapro Exchange, Probinar, and Tapy Maax to deliver
          innovative, secure, and scalable solutions for businesses, driving
          success and efficiency in a decentralized world.
        </p>
        <div className="text-center my-3 text_whites">
          <button className="event_btn">Events</button>
        </div>
        .about-us-action
      </div>
      <section className="planning text_whites my-3 my-lg-0">
        <div className="container py-3 my-lg-5">
          <h5 className="font_inter">How Its Work</h5>
          <h3 className="text-capitalize font_inter">
            Discover Our Innovative Product Line for Business Growth
          </h3>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center gap-4 bg-grays">
                <div className="rounded-border">01</div>
                <div>
                  <h5>Ultrapro Blockchain</h5>
                  <p>
                    Ultrapro Blockchain is a secure, scalable platform that
                    powers decentralized applications and transactions,
                    revolutionizing the future of digital interactions.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 bg-grays mt-3 mb-3 mb-lg-0">
                <div className="rounded-border">02</div>
                <div>
                  <h5>Ultrapro Exchange</h5>
                  <p>
                    Ultrapro Exchange is a secure global platform for trading
                    Bitcoin, Ethereum, BNB, UPRO, and 150+ cryptocurrencies
                    efficiently and safely.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center gap-4 bg-grays">
                <div className="rounded-border">03</div>
                <div>
                  <h5>Probinar</h5>
                  <p>
                    Probinar is a leading platform for blockchain education,
                    offering courses and live classes that empower students to
                    excel in their careers and embrace the future of technology.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 bg-grays mt-3">
                <div className="rounded-border">04</div>
                <div>
                  <h5>Tapy Maax</h5>
                  <p>
                    Tapy Maax is a pioneering electric bike company focused on
                    sustainable transportation, producing innovative,
                    eco-friendly bikes for a cleaner future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Everyone text_whites">
        <div className="container">
          <div className="d-flex flex-column align-items-center justify-content-center text-center">
            <h3 data-aos="fade-up" data-aos-duration="1000">
              Ultrapro Exchange: Our Global Product Video Tour
            </h3>
          </div>

          <div className="row py-3">
            <div className="col-md-3">
              <p>
                Ultrapro Exchange offers real-time price comparisons of leading
                cryptocurrencies across the top 10 exchanges, enabling users to
                track the top 12 crypto coins and identify buying and selling
                opportunities. UPRO, the native cryptocurrency of Ultrapro
                Blockchain, launched at 80 cents after distributing nearly 2
                million UPROs. Stake UPRO to earn guaranteed rewards based on
                your staked amount and duration, ensuring a reliable income
                stream.
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <VideoPlayer videoSrc={Url} />
            </div>
            <div className="col-md-3 d-flex align-items-end">
              <p className="my-3 my-md-0">
                Ultrapro Exchange app has quickly reached 7 million users in
                just 22 days! With a user-friendly interface and a wide range of
                features, it's designed for everyone. Explore our website and
                app to experience innovative solutions firsthand.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wedding  p-3 p-md-5 px-2 mt-md-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-9">
              <h1>Probinar: Learn Blockchain, Lead the Future!</h1>
              <p>
                Probinar provides expert-led blockchain education through live
                online classes. Join over 1000 students and unlock new career
                opportunities in the fast-evolving world of blockchain
                technology.
              </p>
              <Link to="http://www.probinar.in/" target="_blank">
                <button>Learn Now</button>
              </Link>
            </div>
            <div className="col-md-3 text-center">
              <Images link={weddin} styles="img-fluid img-client" />
            </div>
          </div>
        </div>
      </section>
      <section className="moment py-3 py-lg-5 ">
        <div className="container position-relative">
          <Images styles="bg_line-img d-none d-lg-block" link={lineimg} />
          <div className="special bg_org">A Look Back</div>
          <h3
            className="text_whites"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Ultrapro’s Highlights of the Year
          </h3>
          <Events/>
        </div>
      </section>
      <section className="container">
        <Sociel />
      </section>
      {/* <section className="Services py-2 pt-md-5 text_whites">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="bg-orange">
                <h1 className="text-uppercase text-center text-md-start">
                  Celebrating the Launch of Ultrapro’s Event
                </h1>
                <div className="d-flex gap-5 justify-content-center justify-content-md-start ms-md-2">
                  <button>Get Started</button>
                  <button>Learn More</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 pt-2 pt-md-0">
              <div className="position-relative">
                <Images link={service} styles="img-fluid" />
                <Images link={playicon} styles={"position-img"} />
              </div>
              <div className="py-2">
                <h5 className="text-center text-md-start ps-lg-5">Services</h5>
                <div className="d-flex gap-3 gap-lg-5 sociel-icons justify-content-evenly justify-content-md-center align-items-center text-center">
                  <div>
                    <Images link={theme === "dark" ? service2 : filterimg3} />
                    <div>
                      <h1>
                        Celebrate <br /> Innovation
                      </h1>
                    </div>
                  </div>
                  <div>
                    <Images link={theme === "dark" ? service3 : filterimg1} />
                    <h1>
                      Drive <br /> Success
                    </h1>
                  </div>
                  <div>
                    <Images link={theme === "dark" ? service1 : filterimg2} />
                    <h1>
                      Innovate <br /> Together
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Discover-Gallery text_whites">
        <div className="container">
          <div className="py-2 py-md-3 text-center">
            <div className="eve">Events</div>
            <h3 data-aos="fade-up" data-aos-duration="1000">
              Discover Gallery
            </h3>
          </div>F
          <ul
            className="nav nav-pills mb-3 d-flex justify-content-center "
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active mx-3 "
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                ALL
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link "
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                CORPORATE
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link mx-3"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                GROUP
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="0"
            >
              <div className="d-none d-md-block">
                <div className="row pop_ups ">
                  <div className="col-md-4 col-sm-6 ">
                    <Images link={ultraproindoor1} styles="img-fluid " />
                  </div>
                  <div className="col-sm-6 col-md-4 py-2 py-sm-0">
                    <Images link={ultraproindoor2} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6 ">
                    <Images link={ultraproevent} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={ultraproframe} styles="img-fluid" />
                  </div>
                  <div className="col-md-4  col-sm-6  pt-2">
                    <Images link={developer1} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={developer2} styles="img-fluid" />
                  </div>
                  <div className="col-md-4  col-sm-6  pt-2">
                    <Images link={developer3} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={ultraprovisiter} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={developer4} styles="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none">
                <Swiperr />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabIndex="0"
            >
              <div className="d-none d-md-block">
                <div className="row pop_ups ">
                  <div className="col-md-4 col-sm-6 ">
                    <Images link={ultraproindoor1} styles="img-fluid " />
                  </div>
                  <div className="col-sm-6 col-md-4 py-2 py-sm-0">
                    <Images link={ultraproindoor2} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6 ">
                    <Images link={ultraproevent} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={ultraproframe} styles="img-fluid" />
                  </div>
                  <div className="col-md-4  col-sm-6  pt-2">
                    <Images link={developer1} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={developer2} styles="img-fluid" />
                  </div>
                  <div className="col-md-4  col-sm-6  pt-2">
                    <Images link={developer3} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={ultraprovisiter} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={developer4} styles="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none">
                <Swiperr />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
              tabIndex="0"
            >
              <div className="d-none d-md-block">
                <div className="row pop_ups ">
                  <div className="col-md-4 col-sm-6 ">
                    <Images link={ultraproindoor1} styles="img-fluid " />
                  </div>
                  <div className="col-sm-6 col-md-4 py-2 py-sm-0">
                    <Images link={ultraproindoor2} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6 ">
                    <Images link={ultraproevent} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={ultraproframe} styles="img-fluid" />
                  </div>
                  <div className="col-md-4  col-sm-6  pt-2">
                    <Images link={developer1} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={developer2} styles="img-fluid" />
                  </div>
                  <div className="col-md-4  col-sm-6  pt-2">
                    <Images link={developer3} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={ultraprovisiter} styles="img-fluid" />
                  </div>
                  <div className="col-md-4 col-sm-6  pt-2">
                    <Images link={developer4} styles="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none">
                <Swiperr />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default EnhancedEnterprises;
